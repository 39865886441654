import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from "./auth.service";

@Injectable({
	providedIn: 'root'
})
export class UserService {	

	user: any;

	constructor(
		private http: HttpClient,
		private authService: AuthService
	) { }

	getUserInfo() {

		this.authService.checkAuth();

		return new Promise((resolve, reject) => {
			this.http.get(`${environment.apiUrl}/api/user/info`)
				.subscribe({
					next: (data: any) => {
						resolve(data);
					},
					error: (error) => {
						reject(error);
					}
				})
		});
	}

	setUser(user: any){
		this.user = user;
	}

	getUser(){
		return this.user;
	}
}
