import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    selector: 'app-welc-term',
    templateUrl: './welcome-term.component.html',
    styleUrls: ['./welcome-term.component.scss']
})
export class WelcomeTermComponent implements OnInit {
    
    constructor(
        private route: ActivatedRoute, 
        private router: Router,
    ){}
    
    channel: string;
    payload: string;

    isLoading = true;

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            this.payload = params['payload'];
            this.channel = params['channel'];
          });
        this.isLoading = false;
    }

    back(){
        this.router.navigate(['/tnc'], {
            queryParams: {
                channel: this.channel,
                payload: this.payload
            }
        });
    }
}