<div class="container-fluid">
    <a routerLink="..">
        <img class="back-button my-4" src="assets/img/insurance/back_icon.png" alt="">
    </a>
    
    <div class="d-flex justify-content-center mt-5 insan-logo">
        <img src="assets/img/insurance/insan_logo_v2/insan-official-logo-white-font.png" alt="" />
    </div>

    <div class="d-flex justify-content-center text-secondary">
        <section>
            <p class="text-center">                    
                Perlindungan insurans am berkumpulan kepada <span class="text-urobilin font-weight-bold">6 juta Rakyat Selangor berumur  bermula dari 30 hari sehingga  80 tahun</span> dengan premium insurans ditanggung sepenuhnya oleh Kerajaan Negeri Selangor
            </p>

            <p class="text-center">                  
                Inisiatif Kerajaan Negeri untuk <span class="text-urobilin font-weight-bold">meringankan beban rakyat</span> yang dihimpit pelbagai masalah, melindungi rakyat dengan semangat negeri penyayang
            </p>

            <p class="text-center">                                        
                Perlindungan insurans sehingga <span class="text-urobilin font-weight-bold">RM10,000</span> untuk hilang keupayaan kekal dan kematian disebabkan kemalangan
            </p>
        </section>
    </div>

    <p class="text-center text-secondary sponsor mt-4">Inisiatif oleh</p>

    <div class="d-flex justify-content-center mb-4 selangor_coa">
        <img src="assets/img/insurance/coat_of_arms_of_selangor.png" alt="" />
    </div>    
</div>