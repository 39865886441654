<div class="container-fluid px-4">

    <div class="row">
        <div class="col-12">

            <a routerLink="..">
                <img class="back-button my-4" src="assets/img/insurance/back_icon.png" alt="">
            </a>

            <section class="bg-cultured">

                <div class="title bg-rhino text-secondary py-2 text-center">
                    Bagaimana Melakukan Tuntutan?
                </div>

                <div class="intro">
                    <p>Individu yang dilindungi dibawah program INSAN Selangor atau pewaris yang sah boleh melakukan
                        tuntutan dengan menghubungi pihak Rakan Insurans Rasmi yang dibutirkan seperti dibawah:</p>
                </div>

                <div class="d-flex justify-content-center">
                    <img src="assets/img/insurance/amgen_logo.png" alt="">
                </div>

                <div class="text-center">
                    <p class="contact mb-3">
                        Contact Centre: 1 800 88 6333 (8.45am – 5.45pm, Mon – Thurs. 8.45am - 4.45am, Fri)
                    </p>

                    <p class="mb-3">Email:
                        <span>
                            <a class="text-blood" href="mailto:customer@amassurance.com.my">customer@amassurance.com.my</a>
                        </span>
                    </p>
                    
                    <a class="amlink text-blood" href="https://www.amassurance.com.my">www.amassurance.com.my</a>
                </div>

            </section>
        </div>
    </div>

    <app-amgen-contact></app-amgen-contact>  
</div>

<div class="container-fluid px-4">
    <br>
    <div class="row">
        <div class="col-12">
            <section class="bg-cultured">

                <div class="d-flex justify-content-center">
                    <img width="100px" src="assets/img/insurance/Takaful Logo-01.png" alt="">
                </div>
        
                <div class="text-center">
                    <p>Pusat Panggilan Pelanggan</p>
                    <a class="contact mb-3" href="tel:0327239696">
                        03-2723 9696
                    </a>
                    <br>
                    <a class="contact mb-3" href="tel:0327239998">
                        03-2723 9998
                    </a>
                    <br>
                    <br>
                    <p>
                        Isnin-Khamis
                        <br>
                        8:30am - 5:30pm
                        <br>
                        <br>
                        Jumaat
                        <br>
                        8:30am - 4:30p.m
                        <br>
                        <br>
                        Sabtu, Ahad & Cuti Umum Tutup      
                    </p>
        
        
                    <p class="mb-3">
                        <span>
                            <a class="text-blood" href="mailto:ikhlascare@takaful-ikhlas.com.my">ikhlascare@takaful-ikhlas.com.my</a>
                        </span>
                    </p>
        
                    <p class="mb-3">
                        <span>
                            <a class="text-blood" href="mailto:generalsupport@takaful-ikhlas.com.my">generalsupport@takaful-ikhlas.com.my</a>
                        </span>
                    </p>
                    <a> Semak Cawangan</a>
                    <br>
                    <a class="amlink text-blood" href="https://www.takaful-ikhlas.com.my/support/our-branches">https://www.takaful-ikhlas.com.my/support/our-branches</a>
                </div>
        
            </section>
        </div>
    </div>
</div>
