import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ApplicationMoreFormsService } from "@services/applicationMoreForms.service";
import { PolicyService } from "@services/policy.service";
import { UtilService } from "@services/util.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { excludedOccupations } from '@models/occupations';

@Component({
    selector: 'app-application-more',
    templateUrl: './application-more.component.html',
    styleUrls: ['./application-more.component.scss']
})
export class ApplicationMoreComponent implements OnInit {

    applicationInfos: any[] = [];
    
    exemptedJobModalRef: BsModalRef;
    ageModalRef: BsModalRef;


    @ViewChild('ageErrortemplate') ageErrortemplate: TemplateRef<any>;
    @ViewChild('exemptedJobTemplate') exemptedJobTemplate: TemplateRef<any>;

    excludedOccupations = excludedOccupations.sort(this.sortOccupationsByName);

    isSubmitting = false;

    constructor( 
        public formsService: ApplicationMoreFormsService,
        private policyService: PolicyService,
        private router: Router,
        private modalService: BsModalService,
        private utilService: UtilService
    ){}    

    ngOnInit(): void {        
        this.formsService.clearAllForms()
        this.addApplicant()
    }

    activateForm(id:string){
        this.formsService.activateForm(id)
    }

    addApplicant(){
        this.formsService.addApplication()        
    }

    sortOccupationsByName(a,b){
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    }

    continue() {

        this.isSubmitting = true;

        this.applicationInfos = [];

        const base64regex = /data\:image\/.+?\;base64\,/gm;

        this.formsService.applicationForms.forEach( form => {
            this.applicationInfos.push({
                type:'DEPENDENT',
                nameAsId: form.formData.get('name').value,
                idNumber: form.formData.get('icNumber').value,
                religion: form.formData.get('religion').value,
                address: {
                    addressType: "RESIDENTIAL",
                    address1: form.formData.get('address1').value,
                    address2: form.formData.get('address2').value,
                    city: form.formData.get('city').value,
                    postCode: form.formData.get('postCode').value,
                    state: form.formData.get('state').value,
                },
                gender: form.formData.get('gender').value,
                email: form.formData.get('email').value,
                phoneNumber: form.formData.get('phoneNumber').value,
                // occupation: form.formData.get('occupation').value,
                occupation: 'N/A',
                relationship: form.formData.get('relationship').value,
                idFront: this.utilService.resizePhoto(form.formData.get('icPhoto.frontBase64').value, 2*1024*1000).replace(base64regex, ""),
                idBack: this.utilService.resizePhoto(form.formData.get('icPhoto.backBase64').value, 2*1024*1000).replace(base64regex, "")
                
            })            
        } )        

        console.log({
            applicationInfos: this.applicationInfos
        });

        this.addPolicy({
            applicationInfos: this.applicationInfos,            
        })           
    }

    addPolicy = this.utilService.debounce(this._doAddPolicy.bind(this), 3000)
    _doAddPolicy(data:any){
        this.policyService.addPolicy(data).subscribe({
            next: (result: any) => {       
                this.isSubmitting = false;
                this.router.navigate(['/ecert/ecert'])
            },
            error: error => {
                this.isSubmitting = false;
                throw error;
            }
        })        
    }

    handleAgeError(){}

    showExemptedJobList() {
        this.exemptedJobModalRef = this.modalService.show(this.exemptedJobTemplate, {
            class: 'modal-lg modal-dialog-centered',
            ignoreBackdropClick: true,
            backdrop: 'static',
            keyboard: false
        })
    }

    hideExemptedJobList() {
        this.exemptedJobModalRef.hide()        
    }

    cancelAgeModal() {
        this.ageModalRef.hide();
        (window as any).invokeClosePage();
        this.router.navigate(['/home'])
    }

}