<div class="container-fluid px-4">

    <div class="row">
        <div class="col-12 content">

            <app-application-term-details [readOnly]="false"></app-application-term-details>

            <p class="mt-3 agree">
                Dengan menekan butang “Terima” di bawah, saya menerima Terma & Syarat yang tertakluk di bawah program
                INSAN
            </p>

            <div class="d-flex justify-content-center mt-3">
                <button type="button" class="btn btn-rhino" (click)="continue()" [disabled]="isSubmitting">
                    {{ isSubmitting ? 'Sila tunggu...' : 'Terima' }}
                </button>
            </div>

        </div>
    </div>
</div>

<app-application-id-verification 
    *ngIf="verificationModalVisible"
    (onClose)="handleIdVerified()"
></app-application-id-verification>