import { NgModule } from '@angular/core';
import { RouterModule, Router, NavigationStart } from '@angular/router';
import { TranslatorService } from '../core/translator/translator.service';
import { MenuService } from '../core/menu/menu.service';
import { SharedModule } from '../shared/shared.module';
import { PagesModule } from './pages/pages.module';

import { menu } from './menu';
import { routes } from './routes';

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forRoot(routes),
        PagesModule,
    ],
    declarations: [],
    exports: [
        RouterModule
    ]
})

export class RoutesModule {
    constructor(public menuService: MenuService, tr: TranslatorService, private router: Router) {
        menuService.addMenu(menu);

        router.events.subscribe(e => {

            if (e instanceof NavigationStart) {

                if (e.url.match(/\/\?1=1&channel=.+?&payload=.+?/gm)) {

                    const regex = new RegExp(/\/\?1=1&channel=(.+)&payload=(.+)/gm);
                    const match = regex.exec(e.url);

                    this.router.navigate(['/tnc'], {
                        queryParams: {
                            channel: match[1],
                            payload: match[2]
                        }
                    });
                }

                else if (e.url === '/') {
                    this.router.navigate(['/tnc']);
                }

            }
            return;
            // NavigationEnd
            // NavigationCancel
            // NavigationError
            // RoutesRecognized
        });



    }
}
