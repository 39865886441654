import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApplicationFormsService } from '@services/applicationforms.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-application-id-verification',
    templateUrl: './id-verification.component.html',
    styleUrls: ['./id-verification.component.scss']
})
export class AppApplicationIdVerificationComponent implements OnInit {

    @ViewChild('idVerificationTemplate') idVerificationTemplate: TemplateRef<any>;
    
    @Output('onClose') onClose = new EventEmitter();

    idVerificationModalRef: BsModalRef;

    idForm: FormGroup; 


    
    allTouched = false;
    errorRequired = false;
    errorNotMatched = false;

    constructor(
        private modalService: BsModalService,
        private applicationFormService: ApplicationFormsService
    ){}

    ngOnInit(): void {
        setTimeout(()=>{
            this.showVerificationModal()
            this.initForm()
        }, 300)            
    }

    initForm(){
        let idInputs = new FormArray([]);

        for(let i=0; i < 4; i++){
            idInputs.push(new FormGroup({
                idNumber: new FormControl({
                    value: '',
                    disabled: false                    
                }, [
                    Validators.required,                     
                ])
            }))
        }

        this.idForm = new FormGroup({
            ids: idInputs
        })
    }

    showVerificationModal(){
        this.idVerificationModalRef = this.modalService.show(this.idVerificationTemplate,  {
            class: 'modal-lg modal-dialog-centered',
            ignoreBackdropClick: true,
            backdrop: 'static',
            keyboard: false
        })
    }

    get controls() {        
        return (<FormArray>this.idForm.get('ids')).controls;
    }

    checkAllTouched(){        
        this.allTouched = this.controls.every( ( control )=>{            
            return control.get('idNumber').touched           
        } )        
    }

    checkErrorRequired(){
        this.errorRequired = this.controls.some( ( control )=>{             
            return control.get('idNumber').errors?.required
        } ) 
    }

    checkIdMatched(){
        const primaryForm = this.applicationFormService.getPrimaryForm()
        const icLastFour = primaryForm.formData.get('icNumber').value.trim().slice(-4);
        const formLastFour = this.idForm.value.ids.map( id => id.idNumber ).reduce( (o, n) => o.toString() + n.toString() )

        this.errorNotMatched = icLastFour != formLastFour;
    }

    move(index){

        this.errorRequired = false
        this.errorNotMatched = false

        
        const currentControl:FormGroup = this.controls[index] as FormGroup;        

        const inputValue = currentControl.value.idNumber;

        const nextEl = document.getElementById(`input-${index+1}`)
        const prevEl = document.getElementById(`input-${index-1}`)

        if(inputValue){
            if(nextEl){
                nextEl.focus();
            }
        } 
        else{
            if(prevEl){
                prevEl.focus();
            }
        }       
    }   

    onSubmit(){        

        this.idForm.markAllAsTouched();
        this.checkAllTouched();
        this.checkErrorRequired();

        if(!this.idForm.valid){
            return;
        }

        this.checkIdMatched();
        if(this.errorNotMatched){
            return;
        }        

        this.close()       
    }

    close(){
        this.idVerificationModalRef.hide()
        this.onClose.emit()
    }   

}