/*!
 *
 * Angle - Bootstrap Admin Template
 *
 * Version: 4.5.5
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import "@fontsource/mulish";

if (environment.production) {
    enableProdMode();
    if (window) {
        window.console.log = window.console.warn = window.console.info = function () {
            // Don't log anything.
        };
    }
}

let p = platformBrowserDynamic().bootstrapModule(AppModule);
p.then(() => { (<any>window).appBootstrap && (<any>window).appBootstrap(); })
// .catch(err => console.error(err));
