import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateService, TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// import { ErrorInterceptorProvider } from './_services/error.interceptor';

import { AppComponent } from './app.component';

import { CoreModule } from './core/core.module';
import { LayoutMobileModule } from './layout-mobile/layout-mobile.module';
import { SharedModule } from './shared/shared.module';
import { RoutesModule } from './routes/routes.module';
import { JwtModule } from '@auth0/angular-jwt';
import { GraphQLModule } from './graphql.module';
// import { RoutesModule } from './routes/routes.module';

import { GlobalErrorHandler } from '@core/global-error-handler';

export function tokenGetter() {    
    return localStorage.getItem('token');    
}

// https://github.com/ocombe/ng2-translate/issues/218
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserAnimationsModule, // required for ng2-tag-input
        HttpClientModule,
        CoreModule,        
        LayoutMobileModule,        
        SharedModule.forRoot(),
        RoutesModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
                whitelistedDomains: ['wihapi-dev.wavpay.net', 'wihapi.wavpay.net', 'localhost:19321'],
                blacklistedRoutes: []
            }
        }),
        GraphQLModule,
    ],
    providers: [
        {            
        provide: ErrorHandler,
        useClass: GlobalErrorHandler,
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
