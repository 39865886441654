import { Component, OnInit} from '@angular/core'
import { Router } from '@angular/router'
import { ApplicationFormsService } from '@services/applicationforms.service'
import { PolicyService } from '@services/policy.service';
import { UtilService } from '@services/util.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-application-term',
    templateUrl: './application-term.component.html',
    styleUrls: ['./application-term.component.scss']
})
export class ApplicationTermComponent implements OnInit {

    applicationInfos: any[] = [];
    applicationExtraInfos: any;
    isSubmitting = false;
    verificationModalVisible = false;

    constructor(
        private router: Router,
        private applicationFormsService: ApplicationFormsService,
        private policyService: PolicyService,
        private utilService: UtilService        
    ) { }

    ngOnInit() {
        
    }

    continue(){
        this.verificationModalVisible = true;
    }

    doContinue() {

        this.isSubmitting = true;

        const base64regex = /data\:image\/.+?\;base64\,/gm;

        const data = this.applicationFormsService.prepareRequestFormData();

        console.log({data});
        
        this.addPolicy(data)

    }

    addPolicy = this.utilService.debounce(this._doAddPolicy.bind(this), 3000)
    _doAddPolicy(data:any){
        this.policyService.addPolicy(data).subscribe({
            next: (result: any) => {                
                this.isSubmitting = false;
                this.router.navigate(['application', 'confirm'])                
            },
            error: error => {
                this.isSubmitting = false;
                throw error;
            }
        })
    }    

    handleIdVerified(){                
        this.verificationModalVisible = false;
        this.doContinue()
    }
}