export const occupations = [
    { code: 'OCC000001', name: 'AKAUNTAN' },
    { code: 'OCC000002', name: 'PENGHIBUR AKROBAT/ SARKAS' },
    { code: 'OCC000003', name: 'PELAKON / PENGHIBUR (TIDAK TERLIBAT DALAM LAGAK NGERI)' },
    { code: 'OCC000004', name: 'AKTUARI' },
    { code: 'OCC000005', name: 'EKSEKUTIF, PENGURUS, PENGURUS BESAR (PEJABAT) ' },
    { code: 'OCC000006', name: 'EKSEKUTIF, PENGURUS, PENGURUS BESAR, PENYELIA (TAPAK) ' },
    { code: 'OCC000007', name: 'PRAMUGARA/ PRAMUGARI' },
    { code: 'OCC000008', name: 'ARKITEK' },
    { code: 'OCC000009', name: 'TENTERA DARAT/ TENTERA LAUT/ TENTERA UDARA/ ANGGOTA TENTERA ' },
    { code: 'OCC000010', name: 'ARTIS (PELUKIS)' },
    { code: 'OCC000011', name: 'JURUAUDIT' },
    { code: 'OCC000012', name: 'PELAYAN BAR' },
    { code: 'OCC000013', name: 'PAKAR KECANTIKAN/ PENDANDAN RAMBUT/ TUKANG GUNTING RAMBUT' },
    { code: 'OCC000014', name: 'TUKANG BESI' },
    { code: 'OCC000015', name: 'AHLI PERNIAGAAN (BUKAN MANUAL)' },
    { code: 'OCC000016', name: 'PENJUAL/ PENYEMBELIH DAGING' },
    { code: 'OCC000017', name: 'JOKI KERETA' },
    { code: 'OCC000018', name: 'TUKANG KAYU' },
    { code: 'OCC000019', name: 'JURUWANG' },
    { code: 'OCC000020', name: 'KATERER' },
    { code: 'OCC000021', name: 'PEMBANTU KAMAR/ TUKANG KEMAS BILIK' },
    { code: 'OCC000022', name: 'TUKANG MASAK / PEMBUAT ROTI' },
    { code: 'OCC000023', name: 'AHLI KIMIA' },
    { code: 'OCC000024', name: 'PENGAMAL PERUBATAN TRADISIONAL (SINSEH, DUKUN)' },
    { code: 'OCC000025', name: 'KERANI - PEJABAT' },
    { code: 'OCC000026', name: 'TUKANG KASUT' },
    { code: 'OCC000027', name: 'PEMANDU' },
    { code: 'OCC000028', name: 'KONSULTAN' },
    { code: 'OCC000029', name: 'KONTRAKTOR (KERJA MANUAL)' },
    { code: 'OCC000030', name: 'KONTRAKTOR (KERJA PENYELIAAN)' },
    { code: 'OCC000031', name: 'PENARI' },
    { code: 'OCC000032', name: 'DOKTOR GIGI' },
    { code: 'OCC000033', name: 'BUDAK PENGHANTAR' },
    { code: 'OCC000034', name: 'DOKTOR/ PAKAR BEDAH' },
    { code: 'OCC000035', name: 'PENDRAF' },
    { code: 'OCC000036', name: 'EDITOR' },
    { code: 'OCC000037', name: 'JURUELEKTRIK' },
    { code: 'OCC000038', name: 'JURUTERA (PEJABAT)' },
    { code: 'OCC000039', name: 'JURUTERA (TAPAK)' },
    { code: 'OCC000040', name: 'PEKERJA KILANG (TIDAK TERMASUK OPERATOR MESIN)' },
    { code: 'OCC000041', name: 'PELADANG' },
    { code: 'OCC000042', name: 'PEREKA BENTUK' },
    { code: 'OCC000043', name: 'AHLI BOMBA' },
    { code: 'OCC000044', name: 'NELAYAN' },
    { code: 'OCC000045', name: 'PENJUAL BUNGA' },
    { code: 'OCC000046', name: 'PENILIK NASIB' },
    { code: 'OCC000047', name: 'TUKANG KEBUN' },
    { code: 'OCC000048', name: 'AHLI GEOLOGI' },
    { code: 'OCC000049', name: 'TUKANG EMAS' },
    { code: 'OCC000050', name: 'PENJAWAT AWAM/KAKITANGAN KERAJAAN (PENTADBIRAN)' },
    { code: 'OCC000051', name: 'PENJAJA' },
    { code: 'OCC000052', name: 'GURU BESAR' },
    { code: 'OCC000053', name: 'PENGURUS HOTEL (PENTADBIRAN)' },
    { code: 'OCC000054', name: 'PEMBANTU RUMAH/AMAH' },
    { code: 'OCC000055', name: 'SURI RUMAH' },
    { code: 'OCC000056', name: 'EJEN INSURANS (HAYAT/ AM)' },
    { code: 'OCC000057', name: 'JURUBAHASA' },
    { code: 'OCC000058', name: 'PENGATUR CARA TEKNOLOGI MAKLUMAT' },
    { code: 'OCC000059', name: 'WARTAWAN/ JURNALIS' },
    { code: 'OCC000060', name: 'PEGUAM/ PEMBANTU UNDANG-UNDANG' },
    { code: 'OCC000061', name: 'PENSYARAH/ CIKGU/ PROFESOR' },
    { code: 'OCC000062', name: 'PUSTAKAWAN' },
    { code: 'OCC000063', name: 'ANGGOTA PENYELAMAT' },
    { code: 'OCC000064', name: 'TUKANG KUNCI' },
    { code: 'OCC000065', name: 'PENYELARAS KERUGIAN' },
    { code: 'OCC000066', name: 'OPERATOR MESIN/ JURUMESIN' },
    { code: 'OCC000067', name: 'MAJISTRET/ HAKIM' },
    { code: 'OCC000068', name: 'MEKANIK/ FOMEN' },
    { code: 'OCC000069', name: 'PEMBERI PINJAMAN WANG (BERLESEN)' },
    { code: 'OCC000070', name: 'PASTOR/ PADERI/ SAMI' },
    { code: 'OCC000071', name: 'VENDOR SURAT KHABAR' },
    { code: 'OCC000072', name: 'JURURAWAT' },
    { code: 'OCC000073', name: 'PAKAR OPTIK' },
    { code: 'OCC000074', name: 'JURUJUAL/ KAKITANGAN PEMASARAN - LUAR' },
    { code: 'OCC000075', name: 'TUKANG CAT (atas 30 kaki atau 10 tingkat)' },
    { code: 'OCC000076', name: 'PESARA' },
    { code: 'OCC000077', name: 'PEMANDU PERIBADI' },
    { code: 'OCC000078', name: 'AHLI FARMASI' },
    { code: 'OCC000079', name: 'JURUGAMBAR' },
    { code: 'OCC000080', name: 'JURUTERBANG' },
    { code: 'OCC000081', name: 'TUKANG PAIP' },
    { code: 'OCC000082', name: 'POLIS' },
    { code: 'OCC000083', name: 'POSMEN' },
    { code: 'OCC000084', name: 'PENCETAK/ PENERBIT' },
    { code: 'OCC000085', name: 'AHLI SUKAN PROFESIONAL' },
    { code: 'OCC000086', name: 'PENILAI HARTANAH' },
    { code: 'OCC000087', name: 'JURUUKUR BAHAN' },
    { code: 'OCC000088', name: 'PEKERJA KUARI (TIDAK MELETUP)' },
    { code: 'OCC000089', name: 'EJEN HARTANAH' },
    { code: 'OCC000090', name: 'PENYAMBUT TETAMU' },
    { code: 'OCC000091', name: 'REMISIER' },
    { code: 'OCC000092', name: 'SETIAUSAHA/ JURUTRENGKAS' },
    { code: 'OCC000093', name: 'PENGAWAL KESELAMATAN' },
    { code: 'OCC000094', name: 'PEMBANTU KEDAI' },
    { code: 'OCC000095', name: 'PENJAGA STOR' },
    { code: 'OCC000096', name: 'PELAJAR' },
    { code: 'OCC000097', name: 'TUKANG JAHIT' },
    { code: 'OCC000098', name: 'JURUTEKNIK' },
    { code: 'OCC000099', name: 'OPERATOR TELEFON' },
    { code: 'OCC000100', name: 'PEMBALAK' },
    { code: 'OCC000101', name: 'PEMANDU PELANCONG' },
    { code: 'OCC000102', name: 'PENGURUS MAYAT/ PENGGALI KUBUR' },
    { code: 'OCC000103', name: 'PELAYAN (SELAIN RESTORAN)' },
    { code: 'OCC000104', name: 'PELAYAN (RESTORAN)' },
    { code: 'OCC000105', name: 'PENDAWAI' },
    { code: 'OCC000106', name: 'ANAK (BELUM BERSEKOLAH)' },
    { code: 'OCC000107', name: 'KRU KAPAL/ KELASI/ PEMUNGGAH BARANG-BARANG' },
    { code: 'OCC000108', name: 'PENJUAL IKAN' },
    { code: 'OCC000109', name: 'PENOREH GETAH' },
    { code: 'OCC000110', name: 'EJEN PEKERJAAN' },
    { code: 'OCC000111', name: 'PEKERJA AM/ BURUH' },
    { code: 'OCC000112', name: 'PENYELIDIK' },
    { code: 'OCC000113', name: 'PENGANGGUR-LAIN-LAIN' },
    { code: 'OCC000114', name: 'TENTERA DARAT/ TENTERA LAUT/ TENTERA UDARA/ ANGGOTA TENTERA (Perlindungan semasa tidak bertugas sahaja)' },
    { code: 'OCC000115', name: 'KERANI - TAPAK' },
    { code: 'OCC000116', name: 'DIPLOMAT/ AHLI POLITIK' },
    { code: 'OCC000117', name: 'PEGAWAI PERHUBUNGAN TETAMU (PUSAT HIBURAN)' },
    { code: 'OCC000118', name: 'JOKI - DJ' },
    { code: 'OCC000119', name: 'JOKI - LUMBA KUDA' },
    { code: 'OCC000120', name: 'PENJAGA DOBI' },
    { code: 'OCC000121', name: 'PENTERNAK TERNAKAN HIDUP' },
    { code: 'OCC000122', name: 'PRAMUGARA/ PRAMUGARI (Perlindungan semasa tidak bertugas sahaja)' },
    { code: 'OCC000123', name: 'PRAMUGARA/ PRAMUGARI (Darat)' },
    { code: 'OCC000124', name: 'AHLI PERNIAGAAN (Kerja Manual)' },
    { code: 'OCC000125', name: 'KELAB/PERSATUAN (PENTADBIRAN)' },
    { code: 'OCC000126', name: 'CEO/COO/PENGARAH/PENGERUSI/ PENGURUSAN TERTINGGI' },
    { code: 'OCC000127', name: 'PEDAGANG' },
    { code: 'OCC000128', name: 'PENIAGA' },
    { code: 'OCC000129', name: 'POLIS - Perlindungan semasa tidak bertugas sahaja' },
    { code: 'OCC000130', name: 'PERTANIAN' },
    { code: 'OCC000131', name: 'TUKANG CAT (bawah 30 kaki atau 10 tingkat)' }, 
    { code: 'OCC000134', name: 'EJEN RAMALAN (contoh: MAGNUM & EJEN TOTO)' }, 
    
];

export const excludedOccupations = [
	
	{ code: 'OCC000002', name: 'PENGHIBUR AKROBAT/ SARKAS' },
	{ code: 'OCC000007', name: 'PRAMUGARA/ PRAMUGARI' },
	{ code: 'OCC000009', name: 'TENTERA DARAT/ TENTERA LAUT/ TENTERA UDARA/ ANGGOTA TENTERA ' },
	{ code: 'OCC000012', name: 'PELAYAN BAR' },
	{ code: 'OCC000014', name: 'TUKANG BESI' },
	{ code: 'OCC000043', name: 'AHLI BOMBA' },
    { code: 'OCC000044', name: 'NELAYAN' },
    { code: 'OCC000049', name: 'TUKANG EMAS' },
    { code: 'OCC000063', name: 'ANGGOTA PENYELAMAT' },
    { code: 'OCC000069', name: 'PEMBERI PINJAMAN WANG (BERLESEN)' },
    { code: 'OCC000075', name: 'TUKANG CAT (atas 30 kaki atau 10 tingkat)' },
    { code: 'OCC000080', name: 'JURUTERBANG' },
    { code: 'OCC000082', name: 'POLIS' },
    { code: 'OCC000085', name: 'AHLI SUKAN PROFESIONAL' },
    { code: 'OCC000088', name: 'PEKERJA KUARI (TIDAK MELETUP)' },
    { code: 'OCC000093', name: 'PENGAWAL KESELAMATAN' },
    { code: 'OCC000103', name: 'PELAYAN (SELAIN RESTORAN)' },
    { code: 'OCC000107', name: 'KRU KAPAL/ KELASI/ PEMUNGGAH BARANG-BARANG' },
    { code: 'OCC000113', name: 'PENGANGGUR-LAIN-LAIN' },
    { code: 'OCC000122', name: 'PRAMUGARA/ PRAMUGARI (Perlindungan semasa tidak bertugas sahaja)' },
    { code: 'OCC000116', name: 'DIPLOMAT/ AHLI POLITIK' },
    { code: 'OCC000117', name: 'PEGAWAI PERHUBUNGAN TETAMU (PUSAT HIBURAN)' },
    { code: 'OCC000119', name: 'JOKI - LUMBA KUDA' },
    { code: 'OCC000121', name: 'PENTERNAK TERNAKAN HIDUP' },
    { code: 'OCC000134', name: 'EJEN RAMALAN (contoh: MAGNUM & EJEN TOTO)' }, 

]

// export const excludedOccupations = [
	
//     { code: 'OCC000122', name: 'PRAMUGARA/ PRAMUGARI (Perlindungan semasa tidak bertugas sahaja)' },
//     { code: 'OCC000116', name: 'DIPLOMAT/ AHLI POLITIK' },
    
// ]

export const excludedOccupationsGrouped = {

    outOfWorkingHours: [
        { code: 'OCC000002', name: 'PENGHIBUR AKROBAT/ SARKAS' },
        { code: 'OCC000009', name: 'TENTERA DARAT/ TENTERA LAUT/ TENTERA UDARA/ ANGGOTA TENTERA ' },
        { code: 'OCC000012', name: 'PELAYAN BAR' },
	    { code: 'OCC000014', name: 'TUKANG BESI' },
        { code: 'OCC000043', name: 'AHLI BOMBA' },
        { code: 'OCC000044', name: 'NELAYAN' },
        { code: 'OCC000049', name: 'TUKANG EMAS' },
        { code: 'OCC000119', name: 'JOKI - LUMBA KUDA' },
        { code: 'OCC000063', name: 'ANGGOTA PENYELAMAT' },
        { code: 'OCC000080', name: 'JURUTERBANG' },
        { code: 'OCC000082', name: 'POLIS' },
        { code: 'OCC000085', name: 'AHLI SUKAN PROFESIONAL' },
        { code: 'OCC000088', name: 'PEKERJA KUARI (TIDAK MELETUP)' },
        { code: 'OCC000093', name: 'PENGAWAL KESELAMATAN' },
        { code: 'OCC000107', name: 'KRU KAPAL/ KELASI/ PEMUNGGAH BARANG-BARANG' },
        { code: 'OCC000007', name: 'PRAMUGARA/ PRAMUGARI' },
        { code: 'OCC000121', name: 'PENTERNAK HAIWAN HIDUP' },
    ],

    uncovered: [
        { code: 'OCC000134', name: 'EJEN RAMALAN (contoh: MAGNUM & EJEN TOTO)' }, 
        { code: 'OCC000117', name: 'PEGAWAI PERHUBUNGAN TETAMU (PUSAT HIBURAN)' },
        { code: 'OCC000069', name: 'PEMBERI PINJAMAN WANG (BERLESEN)' },
        { code: 'OCC000075', name: 'TUKANG CAT (atas 30 kaki atau 10 tingkat)' },
        { code: 'OCC000135', name: 'PERNIAGAAN/AKTIVITI HARAM/TIDAK BERLESEN' },
        { code: 'OCC000103', name: 'PELAYAN (SELAIN RESTORAN)' },
        { code: 'OCC000113', name: 'PENGANGGUR (SELAIN DARIPADA AKIBAT PEMBERHENTIAN ATAU SABATIKAL)' },
                 
    ]
}




