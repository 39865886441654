import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: "root",
})
export class AuthService {
	
	jwtHelper = new JwtHelperService();

	constructor(private http: HttpClient) { }

	authenticateUser(channel: string, payload: string) {
		return new Promise( (resolve, reject) => {
			this.http.post(`${environment.apiUrl}/api/auth/login-with-token`, { channel, payload })
			.subscribe({
				next: (data: any) => {
					if (data.data?.authToken) {
						localStorage.setItem('token', data.data.authToken);
						resolve(true);						
					}
					else{
						reject(new Error("Data undefined"));
					}								
				},
				error: (error)=>{
					reject(error);
				}
			})
		} )	
	}

	checkAuth(){
		const token = localStorage.getItem('token');
		if (token == null || this.jwtHelper.isTokenExpired(token)) {
			this.logout();
			return;
		}
	}

	logout() {		
		localStorage.removeItem('token');
		return window.location.href = '/home';
	}

	

	

}