<div>
    <app-application-form 
    *ngFor="let form of applicationFormsService.applicationForms" 
    key 
    [hidden]="!form.active"
    [applicantType]="form.type" 
    [applicationId]="form.applicationId"
    [id]="form.id" 
    (onAgeError)="handleAgeError($event)"
    (onShowExemptedJobList)="showExemptedJobList()"       
>
</app-application-form>
</div>

<ng-template #ageErrortemplate>
    <div class="ageExceededModal modal-body bg-cultured d-flex flex-column justify-content-center">

        <div>
            <div class="title font-weight-bold text-center">Harap Maaf</div>

            <div class="text-center body" *ngIf="ageErrorCode === 'PRIMARY_BELOW_18'">
                Pemohon berusia bawah 18 tahun hanya <br />dibenarkan untuk
                menjadi penama. Sila daftar <br />sebagai penama di bawah ibu
                atau bapa anda.
            </div>

            <div class="text-center body" *ngIf="ageErrorCode === 'DEPENDENT_BELOW_30'">
                Harap maaf. Penama berusia kurang dari 30 hari tidak dibenarkan untuk mendaftar.
            </div>

            <div class="text-center body" *ngIf="ageErrorCode === 'PRIMARY_ABOVE_80'">
                Harap maaf. Pemohon berusia lebih dari 80 tahun tidak dibenarkan untuk mendaftar.
            </div>

            <div class="text-center body" *ngIf="ageErrorCode === 'DEPENDENT_ABOVE_80'">
                Harap maaf. Penama berusia lebih dari 80 tahun tidak dibenarkan untuk mendaftar.
            </div>

            <div class="d-flex justify-content-center">
                <button type="button" class="btn btn-rhino continue" (click)="cancelAgeModal()">
                    Keluar
                </button>
            </div>
        </div>

    </div>
</ng-template>

<ng-template #exemptedJobTemplate>
    <div class="exemptedJobModal modal-body bg-cultured d-flex flex-column justify-content-center">

        <div>
            <div class="title font-weight-bold mb-3">Pekerjaan Terkecuali</div>

            <div class="body mb-4">
                Pekerjaan yang berbahaya/terlibat dengan persekitaran berbahaya tidak terlindung di bawah Program INSAN
                ini.
            </div>

            <div class="mb-4">

                <div *ngFor="let occ of excludedOccupations; index as i" >
                    {{i+1}}. {{occ.name}}
                </div>
              
            </div>

            <div class="d-flex justify-content-center">
                <button type="button" class="btn btn-rhino continue" (click)="hideExemptedJobList()">
                    OK
                </button>
            </div>


        </div>

    </div>
</ng-template>