export const contactNum = [
    { code: 'CON000001', number: '60162514199' },
    { code: 'CON000002', number: '60162504199' },
    { code: 'CON000003', number: '60162534199' },
    { code: 'CON000004', number: '60164354199' },
    { code: 'CON000005', number: '60164474199' },
    { code: 'CON000006', number: '60149536627' },
    { code: 'CON000007', number: '60149066627' },
    { code: 'CON000008', number: '60109456627' },
    { code: 'CON000009', number: '60149516627' },
    { code: 'CON000010', number: '60168416627' },
    { code: 'CON000011', number: '60103926627' },
    { code: 'CON000012', number: '60103816627' },

];





