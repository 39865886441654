import { HttpErrorResponse } from "@angular/common/http";
import { Injectable, ErrorHandler, NgZone } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { WindowRef } from "./windows.service";

@Injectable({
    providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler {
    constructor(
        private zone: NgZone,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private windowsService: WindowRef
    ) { }

    handleError(error: any) {

        console.log("Handling error");
        console.log(error);        


        // Check if it's an error from an HTTP response
        // if (!(error instanceof HttpErrorResponse)) {
        //   error = error.rejection; // get the error object
        // }
        // this.zone.run(() =>
        //   this.errorDialogService.openDialog(
        //     error?.message || 'Undefined client error',
        //     error?.status
        //   )
        // );

        if (error instanceof HttpErrorResponse) {

            console.log("Normal Error");

            if (error.error?.error?.[0]) {
                const errorMessage = error.error.error[0];

                console.error(errorMessage);                
                this.toastError(errorMessage, 'Error')

            }
            else if(error?.message){
                this.toastError(error.message, 'Error')
            } 
                   
            
        }

        // unhandled promise rejection
        else if (error?.rejection && error.rejection instanceof HttpErrorResponse) {

            console.log("Promise Error");

            const errorObject = error.rejection;            

            if (errorObject.error?.error?.[0]) {
                const errorMessage = errorObject.error.error[0];

                if (
                    errorMessage.includes("javassist.NotFoundException") ||
                    errorMessage.includes("No primary application")
                ) {
                    if (
                        errorObject.location === 'home' ||
                        errorObject.location === 'ecert-summary'
                    ) {
                        return;
                    }
                   
                    this.toastError(errorMessage, 'Error')
                }
                else {
                                        

                    // maybe to show some error toast
                    this.toastError(errorMessage, 'Error')
                }
            }
            else if(errorObject?.message){
                this.toastError(errorObject.message, 'Error')
            }
        } 

        else{

            if(error.scope === 'searchPostCode'){
                return;
            }

            if(error?.message){
                this.toastError(error.message, 'Error')
            }            
        }
        

        
    }

    toastError(errorMessage:string, title:string){
        this.zone.run(() => {
            // show toast for other page
            this.toastr.error(errorMessage, title, {
                disableTimeOut: true,
                toastClass: 'app-toastr ngx-toastr',
                positionClass: 'toast-bottom-center'
            })
        })
    }
}