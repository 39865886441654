import { Component } from '@angular/core';



@Component({
    selector: 'app-layout-mobile-home',
    templateUrl: './layout-mobile-home.component.html',
    styleUrls: ['./layout-mobile-home.component.scss']
})
export class LayoutMobileHomeComponent {
    constructor() {}   
}


