<div class="row">
    <div class="col-12 intro">
        <a class="text-rhino font-weight-bold">Muat Naik Salinan Kad Pengenalan</a>
        <p class="form-text text-muted">Sila muat naik salinan bahagian depan dan belakang kad
            pengenalan</p>
    </div>    
</div>

<div class="row" [formGroup]="form">
    <div class="col-12" formGroupName="icPhoto">
        

        <div class="p-2 bg-cultured">

            <div class="font-weight-bold ic-title">Salinan Bahagian Hadapan</div>

            <input type="file" accept="image/*" (change)="showPreviewFront($event)" (click)="imageInputClicked($event)" formControlName="front">

            <img *ngIf="frontImageSrc" id="blah" [src]="frontImageSrc || 'http://placehold.it/180'" alt="your image" class="mt-3 w-100" />
            
           
        </div>

        <span *ngIf="
            form.get('icPhoto.front').touched &&
            form.get('icPhoto.front').errors &&
            form.get('icPhoto.front').errors['required']"
            class="help-block mt-1 error-message">
            Front ic photo required
        </span>

        <div class="mt-3 p-2 bg-cultured">

            <div class="font-weight-bold ic-title">Salinan Bahagian Belakang</div>

            <input type="file" accept="image/*" (change)="showPreviewBack($event)" (click)="imageInputClicked($event)" formControlName="back">

            <img *ngIf="backImageSrc" id="blah" [src]="backImageSrc || 'http://placehold.it/180'" alt="your image" class="mt-3 w-100" />
        </div>

        <span *ngIf="
            form.get('icPhoto.back').touched &&
            form.get('icPhoto.back').errors &&
            form.get('icPhoto.back').errors['required']"
            class="help-block mt-1 error-message">
            Back ic photo required
        </span>
        


    </div>
</div>