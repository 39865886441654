import { Injectable } from '@angular/core';
import { ApplicationFormsInterface } from './applicationforms.service';

@Injectable({
    providedIn: 'root'
})
export class ApplicationMoreFormsService {

    applicationForms: ApplicationFormsInterface[] = [];
    showingForm: boolean = false;

    addApplication(){               

        const id = Math.random().toString(36).slice(2)

        this.applicationForms.push({
            id,
            type: 'DEPENDENT',
            active: true            
        })         

        this.activateForm(id)
            
        return id;
    }

    updateForm(id, formData){
        const theForm = this.applicationForms.find( form => form.id == id )
        theForm.formData = formData;         
    }    

    deactivateAllForm(){
        if(this.applicationForms.length){
            this.applicationForms.forEach( form => form.active = false )
        }
        this.showingForm = false;
    }

    activateForm(id:string){

        this.deactivateAllForm()
        
        this.applicationForms.find( form => form.id === id ).active = true;
        this.showingForm = true;
        window.scrollTo({ top: 0, behavior: 'smooth' });    
        
    }  

    removeForm(id:string|number){
        const formIndex = this.applicationForms.findIndex( form => form.id === id );        
        this.applicationForms.splice(formIndex, 1);        
        this.deactivateAllForm()
    }

    clearAllForms(){
        this.applicationForms = [];
    }


}