import { Component, OnInit } from '@angular/core';
import { ApplicationFormsService } from '@services/applicationforms.service'
import { ApplicationFormExtraInterface } from '@models/applicationformextra'
import { FormGroup, FormControl, Validators, AbstractControl } from "@angular/forms";
import { Router } from '@angular/router';

@Component({
    selector: 'app-application-form-extra',
    templateUrl: './application-form-extra.component.html',
    styleUrls: ['./application-form-extra.component.scss']
})
export class ApplicationFormExtraComponent implements OnInit {

    formModel: ApplicationFormExtraInterface = {
        income: '',
        car: '',
        home: '',
        loan:'',
    }

    applicationFormExtra: FormGroup;

    constructor(
        private applicationFormsService: ApplicationFormsService,
        private router:Router
    ){}

    ngOnInit(){
        this.initForm()
    }

    initForm(){
        this.applicationFormExtra = new FormGroup({
            income: new FormControl('',[Validators.required]),
            car: new FormControl('',[Validators.required]),
            home: new FormControl('',[Validators.required]),
            loan: new FormControl('',[Validators.required]),
        })
    }

    continue(){
        this.applicationFormsService.updateApplicationFormExtra(this.applicationFormExtra);
        this.router.navigate(['application', 'term'])
        
    }
}