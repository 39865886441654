import { Component } from '@angular/core';



@Component({
    selector: 'app-layout-mobile-home-main',
    templateUrl: './layout-mobile-home-main.component.html',
    styleUrls: ['./layout-mobile-home-main.component.scss']
})
export class LayoutMobileHomeMainComponent {
    constructor() {}   
}


