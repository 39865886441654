import { Component, Input } from "@angular/core";

@Component({
    selector: 'app-simple-spinner',
    templateUrl: './simple-spinner.component.html',
    styleUrls: ['./simple-spinner.component.scss']
})
export class AppSimpleSpinner {
    
    @Input() color: string = '#656565';

}