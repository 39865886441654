import { Component, Input } from "@angular/core";
import { policyType } from "@services/policy.service";

@Component({
    selector: 'app-ecert-summary-detail',
    templateUrl: './ecert-summary-detail.component.html',
    styleUrls: ['./ecert-summary-detail.component.scss']
})
export class EcertSummaryDetailComponent {

    @Input() policies:policyType[];


}