import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationFormsService } from '@services/applicationforms.service'
import { PolicyService } from '@services/policy.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { excludedOccupations } from '@models/occupations';


@Component({
    selector: 'app-application-component',
    templateUrl: './application.component.html',
    styleUrls: ['./application.component.scss']
})
export class ApplicationComponent implements OnInit {

    @ViewChild('ageErrortemplate') ageErrortemplate: TemplateRef<any>;
    @ViewChild('exemptedJobTemplate') exemptedJobTemplate: TemplateRef<any>;

    ageModalRef: BsModalRef;
    exemptedJobModalRef: BsModalRef;
    userInfo: any;
    applicationId:string = '';
    applicationType:string = '';
    ageErrorCode:string = '';
    ageErrorExit:boolean = true;

    excludedOccupations = excludedOccupations.sort(this.sortOccupationsByName);    

    constructor(
        public applicationFormsService: ApplicationFormsService,
        private modalService: BsModalService,        
        private router: Router,
        private route: ActivatedRoute,
        private policyService: PolicyService
    ) { }

    ngOnInit(): void { 
        
                
        this.applicationFormsService.clearAllForms();                
        this.applicationId = this.route.snapshot.queryParams.applicationId;
        this.applicationType = this.route.snapshot.queryParams.applicationType;        

        this.applicationFormsService.addApplication(
            this.applicationType ? this.applicationType : 'PRIMARY', 
            this.applicationId
        );
            
    }

    sortOccupationsByName(a,b){
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    }

    handleAgeError({code, exit}) {

        this.ageErrorCode = code;
        this.ageErrorExit = exit;        

        this.ageModalRef = this.modalService.show(this.ageErrortemplate, {
            class: 'modal-lg modal-dialog-centered',
            ignoreBackdropClick: true,
            backdrop: 'static',
            keyboard: false
        })
    }

    showExemptedJobList() {
        this.exemptedJobModalRef = this.modalService.show(this.exemptedJobTemplate, {
            class: 'modal-lg modal-dialog-centered',
            ignoreBackdropClick: true,
            backdrop: 'static',
            keyboard: false
        })
    }

    hideExemptedJobList() {
        this.exemptedJobModalRef.hide()        
    }

    cancelAgeModal() {
        this.ageModalRef.hide();
        if(this.ageErrorExit){            
            (window as any).invokeClosePage();
            this.router.navigate(['/home'])
        }
        
    }

    
    

}