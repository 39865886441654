import { Injectable } from "@angular/core";
import { Apollo, gql } from 'apollo-angular';
import { map } from "rxjs/operators";


@Injectable({
  providedIn: "root",
})
export class GraphqlCommonService {
  constructor(private commonGraph: Apollo) { }
  

  getNatureOfBusinessList() {
    return this.commonGraph.query<any>({
      query: gql`
        query {
          nature_of_business {
            code
            displayText
          }
        }
      `
    }).pipe(map((result) => result.data["nature_of_business"]))
    
  }

  // getTypeOfOccupationList() {
  //   return this.apollo
  //     .query<Query>({
  //       query: gql`
  //         {
  //           type_of_occupation {
  //             code
  //             displayText
  //           }
  //         }
  //       `,
  //     })
  //     .pipe(map((result) => result.data["type_of_occupation"]));
  // }

  // getTypeOfCddList() {
  //   return this.apollo
  //     .query<Query>({
  //       query: gql`
  //         {
  //           getTypeOfCDDType {
  //             cdd_type
  //             cdd_description
  //           }
  //         }
  //       `,
  //     })
  //     .pipe(map((result) => result.data["getTypeOfCDDType"]));
  // }

  searchPostCode(postcode: string) {

    return this.commonGraph
      .query<any>({
        query: gql`
          query postcode($postCode: String) {
            postcode(postcode: $postCode) {
              state_name
              state_short_name
              postcode
              cities {
                city
              }
            }
          }
        `,
        variables: { "postCode": postcode }
      })
      .pipe(map((result) => result.data["postcode"]));
  }
}
