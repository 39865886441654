import { Component, OnInit } from "@angular/core";

@Component({
    selector: 'app-term',
    templateUrl: './term.component.html',
    styleUrls: ['./term.component.scss']
})
export class TermComponent implements OnInit {
    
    
    ngOnInit(): void {
        
    }    
}