<div class="container-fluid px-4">

    <h1 class="text-eerie text-center title border-bottom pb-4">
        Tambah Nama Pemohon
    </h1>
    
    <div class="row" *ngIf="!formsService.showingForm">
        <div class="col-12">
            <div class="applicant-list h-100">
                <ng-container *ngIf="formsService.applicationForms.length">
                    <div class="p-2 bg-cultured text-primary name mb-1" (click)="activateForm(form.id)" *ngFor="let form of formsService.applicationForms">
                        {{ form.formData.get('name').value }}
                    </div>
                </ng-container>

                <div class="d-flex align-items-center justify-content-center h-100" *ngIf="!formsService.applicationForms.length">
                    <div class="text-center">
                        Tiada rekod. Sila tekan butang tambah untuk menambah pemohon.
                    </div>                    
                </div>

            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="form-list">                
                <app-application-more-form
                    *ngFor="let form of formsService.applicationForms"
                    key 
                    [hidden]="!form.active"                    
                    [id]="form.id" 
                    (onAgeError)="handleAgeError()"
                    (onShowExemptedJobList)="showExemptedJobList()"                    
                >
                </app-application-more-form>                                
            </div>
        </div>
    </div>

    <div class="row" *ngIf="!formsService.showingForm">

            <div class="col-12">
                <div class="d-flex justify-content-around">
                    <button class="btn btn-rhino control" (click)="addApplicant()">
                        Tambah Pemohon
                    </button>
                
        
                
                    <button class="btn btn-rhino control" (click)="continue()" [disabled]="!formsService.applicationForms.length || isSubmitting">
                        {{ isSubmitting ? 'Sila tunggu...' : 'Teruskan' }} 
                    </button>
                </div>
            </div>
        
            
        
    </div>

</div>

<ng-template #ageErrortemplate>
    <div class="ageExceededModal modal-body bg-cultured d-flex flex-column justify-content-center">

        <div>
            <div class="title font-weight-bold text-center">Harap Maaf</div>

            <div class="text-center body">
                Pemohon berusia bawah 18 tahun hanya <br />dibenarkan untuk
                menjadi penama. Sila daftar <br />sebagai penama di bawah ibu
                atau bapa anda.
            </div>

            <div class="d-flex justify-content-center">
                <button type="button" class="btn btn-rhino continue" (click)="cancelAgeModal()">
                    Keluar
                </button>
            </div>
        </div>

    </div>
</ng-template>

<ng-template #exemptedJobTemplate>
    <div class="exemptedJobModal modal-body bg-cultured d-flex flex-column justify-content-center">

        <div>
            <div class="title font-weight-bold mb-3">Pekerjaan Terkecuali</div>

            <div class="body mb-4">
                Pekerjaan yang berbahaya/terlibat dengan persekitaran berbahaya tidak terlindung di bawah Program INSAN
                ini.
            </div>

            <div class="mb-4">
                <div *ngFor="let occ of excludedOccupations; index as i" >
                    {{i+1}}. {{occ.name}}
                </div>
            </div>

            <div class="d-flex justify-content-center">
                <button type="button" class="btn btn-rhino continue" (click)="hideExemptedJobList()">
                    OK
                </button>
            </div>


        </div>

    </div>
</ng-template>