import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { EcertSharedModule } from '@routes/ecert/ecert-shared.module';
import { ApplicationComponent } from './application/application.component'
import { ApplicationFormComponent } from './application-form/application-form.component'
import { ApplicationFormExtraComponent } from './application-form-extra/application-form-extra.component'
import { ApplicationTermComponent } from './application-terms/application-term.component'
import { ApplicationConfirmComponent } from './application-confirm/application-confirm.component'
import { ApplicationTermDetailsComponent } from './application-terms/application-term-details/details.component'
import { ApplicationUploadIC } from './application-uploadic/application-uploadic.component';
import { ApplicationMoreComponent } from './application-more/application-more.component';
import { ApplicationMoreFormComponent } from './application-more/application-more-form/application-more-form.component';
import { AppApplicationIdVerificationComponent } from './application-terms/id-verification/id-verification.component';


const routes: Routes = [        
    { path: '', component: ApplicationComponent},
    { path: 'extra', component: ApplicationFormExtraComponent },
    { path: 'term', component: ApplicationTermComponent },
    { path: 'confirm', component: ApplicationConfirmComponent },
    { path: 'more', component: ApplicationMoreComponent },

];

@NgModule({
    imports: [
        SharedModule,
        EcertSharedModule,
        RouterModule.forChild(routes),
    ],
    declarations: [
        ApplicationComponent,
        ApplicationFormComponent,
        ApplicationFormExtraComponent,
        ApplicationTermComponent,
        ApplicationConfirmComponent,
        ApplicationUploadIC,
        ApplicationMoreComponent,
        ApplicationMoreFormComponent,
        AppApplicationIdVerificationComponent
    ],
    exports: [
        RouterModule,
        ApplicationComponent,
    ],
    providers: [

    ]
})
export class ApplicationModule {

   
 }
