import { Component, Inject, Input, OnInit } from "@angular/core";
import { DOCUMENT } from "@angular/common";

@Component({
    selector: 'app-application-term-details',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.scss']
})
export class ApplicationTermDetailsComponent implements OnInit {

    @Input() readOnly:boolean;

    constructor(@Inject(DOCUMENT) private document: Document){}

    ngOnInit(): void {
        // console.log({readOnly: this.readOnly});
    }
    
    goToAmAssurance():void {
        this.document.location.href = 'https://www.amassurance.com.my';
    }

    goToTakaful():void {
        this.document.location.href = 'www.takaful-ikhlas.com.my';
    }

}