
<div class="nav bg-rhino d-flex align-items-center">
    <a [routerLink]="['/home']">
        <em class="fa fa-home fa-lg text-white"></em>
        <p class="mb-0">Home</p>
    </a>

    <a href="#" [routerLink]="['/application', 'more']" *ngIf="showAddApplicantMenu">
        <!-- <em class="fas fa-file-invoice fa-lg text-white"></em> -->
        <em class="fa fa-user-plus fa-lg text-white"></em>
        <p class="mb-0">Tambah</p>
    </a>

    <a href="#" [routerLink]="['/ecert/ecert']">
        <!-- <em class="fas fa-file-invoice fa-lg text-white"></em> -->
        <img src="assets/img/insurance/policy_icon.png" alt="">
        <p class="mb-0">e-Cert</p>
    </a>

    <a (click)="showMore($event)">
        <em class="fas fa-ellipsis-h fa-lg text-white" #moreIcon></em>
        <p class="mb-0">More</p>
    </a>

</div>

<div *ngIf="moreMenuVisible" class="d-flex flex-column pop-menu bg-rhino" [ngStyle]="menuStyle">
    <a (click)="navigateAway()" class="text-secondary" [routerLink]="['/about-ihsan']">Tentang INSAN</a>
    <a (click)="navigateAway()" class="text-secondary" [routerLink]="['/term-condition']">Lihat Terma</a>
    <a (click)="navigateAway()" class="text-secondary" [routerLink]="['/claim']">Tuntutan</a>

    <a class="text-secondary" (click)="showExemptedJobList()">Pekerjaan&nbsp;Dikecualikan</a>
    <a class="text-secondary" (click)="showFAQ()">FAQ</a>
    <a class="text-secondary" (click)="showContactUs()">Hubungi&nbsp;Kami</a>
</div>

<ng-template #exemptedJobTemplate>
    <div class="exemptedJobModal modal-body bg-cultured d-flex flex-column justify-content-center">

        <div>
            <div class="title font-weight-bold mb-3">Pekerjaan Terkecuali</div>

            <div class="body mb-4">
                Pekerjaan yang berbahaya/terlibat dengan persekitaran berbahaya tidak terlindung dibawah SKIM INSURANS AM SELANGOR (INSAN) melainkan kemalangan yang melayakkan sebarang tuntutkan dilakukan berlaku ketika diluar waktu bertugas
            </div>

            <div class="mb-4">

                <strong class="mb-2">Perlindungan Diluar Waktu Bertugas:</strong>

                <div *ngFor="let occ of excludedOutOfWorkingHours; index as i" >
                    {{i+1}}. {{occ.name}}
                </div>

            </div>

            <div class="mb-4">

                <strong class="mb-2">Tidak Dilindungi:</strong>

                <div *ngFor="let occ of excludedUncovered; index as i" >
                    {{i+1}}. {{occ.name}}
                </div>

            </div>

            <div class="d-flex justify-content-center">
                <button type="button" class="btn btn-rhino continue" (click)="hideExemptedJobList()">
                    OK
                </button>
            </div>


        </div>

    </div>
</ng-template>

<ng-template #faqTemplate>
    <div class="faqModal modal-body bg-cultured d-flex flex-column justify-content-center">

        <div>
            <div class="title font-weight-bold mb-3">FAQ</div>

            <div class="mb-4">

                <strong class="mb-2">Apakah Skim Insurans Am Selangor (Program INSAN)?</strong>

                <div>
                    Program Insan adalah inisiatif Kerajaan Negeri Selangor yang bermula sejak 1 Oktober 2022 dan bertujuan untuk meringankan beban rakyat yang dihimpit oleh pelbagai masalah pasca pandemik dengan memberi perlindungan Insurans Kemalangan Diri Berkumpulan percuma kepada 6 juta rakyat di Selangor yang layak berumur bermula dari 30 hari sehingga 80 tahun.
                </div>

            </div>

            <div class="mb-4">

                <strong class="mb-2">Apakah Insurans Kemalangan Diri Berkumpulan? </strong>

                <div>
                    Insurans Kemalangan Diri Berkumpulan adalah pelan insurans am secara berkelompok untuk melindungi kehilangan upaya kekal atau kematian akibat kemalangan kepada 6 juta rakyat di Negeri Selangor.
                </div>

            </div>

            <div class="mb-4">

                <strong class="mb-2">Siapakah yang layak untuk menerima perlindungan di bawah Program INSAN?  </strong>

                <div>
                    Warganegara Malaysia berusia 30 hari sehingga 80 tahun yang:
                </div>

                <div>
                    i. Kad Pengenalan yang mempunyai kod negeri Selangor; (Kod 10, 41, 42, 43, 44)
                </div>

                <div>
                    ii. Kad Pengenalan beralamat di negeri Selangor; atau ii) Pengundi yang berdaftar di negeri Selangor.
                </div>

            </div>

            <div class="mb-4">

                <strong class="mb-2">Bagaimanakah untuk memohon perlindungan Program INSAN?</strong>

                <div>
                    i) Muat turun aplikasi eWallet Wavpay di Google Playstore, Apple Store atau Huawei AppGallery; atau
                </div>

                <div>
                    ii) Daftar di Pejabat Ahli Dewan Negeri Selangor.
                </div>

            </div>

            <div class="mb-4">

                <strong class="mb-2">Apakah tarikh-tarikh penting yang perlu diketahui? </strong>

                <div>
                    i) Tarikh akhir pendaftaran bagi INSAN konvensional adalah pada 30 September 2023
                </div>

                <div>
                    ii) Tarikh akhir pendaftaran bagi INSAN takaful adalah pada 3 Ogos 2024.
                </div>

                <div>
                    iii) Tempoh Perlindungan  aktif  selama 12 bulan bermula dari tarikh insurans/takaful didaftarkan.
                </div>

            </div>

            <div class="mb-4">

                <strong class="mb-2">Apakah INSAN? perlindungan yang diberikan menerusi Program </strong>

                <div>
                    Skim INSAN Konvensional menawarkan perlindungan sosial RM10,000 untuk kes kematian dan kes hilang upaya kekal akibat kemalangan dan tambahan RM1,000 untuk perbelanjaan pengebumian untuk kes kematian.
                </div>

                <div>
                    Manakala Skim INSAN Takaful menawar perlindungan di antara RM10,000 sehingga RM20,000 bagi kes kematian atau kes hilang upaya kekal akibat kemalangan, dan tambahan RM1,000 untuk perbelanjaan pengebumian untuk kes kematian serta tambahan faedah RM50/hari sehingga 30 hari untuk kemasukan wad.
                </div>

            </div>

            <div class="mb-4">

                <strong class="mb-2">Apakah beza di antara pilihan Skim Insurans Konvensional atau Takaful? </strong>

                <div>
                    Orang Islam diberi keutamaan perlindungan melalui Skim INSAN Takaful (disediakan oleh Takaful Ikhlas General Berhad), manakala orang bukan Islam dilindungi di bawah Skim INSAN Konvensional (disediakan oleh AmGeneral Insurance Berhad).
                </div>

            </div>

            <div class="mb-4">

                <strong class="mb-2">Apakah prosedur untuk melakukan tuntutan Program INSAN? </strong>

                <div>
                    Pemegang sijil yang dilindungi di bawah Skim INSAN ataupun waris boleh mengemukakan sijil perlindungan ke rakan insurans/takaful atau pengendali perkhidmatan untuk memulakan proses tuntutan. Rakan insurans/takaful dan pengendali perkhidmatan akan memberikan khidmat bantuan kepada penuntut sepanjang proses tuntutan. Bagi tujuan tuntutan, beberapa dokumen dan borang tuntutan perlu dilengkapkan dan diserahkan kepada rakan insurans/takaful atau pengendali perkhidmatan. Senarai dokumen dan borang boleh didapati di laman sesawang <a class="text-primary" (click)="goToProgramInsan()">www.programinsan.com/tuntutan</a>
                </div>

            </div>

            <div class="mb-4">

                <strong class="mb-2">Apakah peranan Wavpay, AmGeneral Insurance Bhd dan Takaful Ikhlas General Bhd dalam pengendalian skim INSAN?</strong>

                <div>
                    WAVPAY bukan penyedia insurans/takaful tetapi hanya pengendali perkhidmatan. Mereka bertanggungjawab memudahkan anda sebagai pengguna untuk mengakses polisi insurans/takaful yang ditawarkan oleh Rakan Kongsi Insurans/Takaful iaitu AmGeneral Insurance Bhd dan Takaful Ikhlas General Bhd.
                </div>

            </div>

            <div class="d-flex justify-content-center">
                <button type="button" class="btn btn-rhino continue" (click)="hideFAQ()">
                    OK
                </button>
            </div>


        </div>

    </div>
</ng-template>

<ng-template #contactUsTemplate>
    <div class="exemptedJobModal modal-body bg-cultured d-flex flex-column justify-content-center">

        <div>
            <div class="title font-weight-bold mb-3">Hubungi Kami</div>

            <!-- <div class="body mb-4">
                This is Hubungi Kami
            </div> -->
            <div class="mb-4">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th width="20%">
                                <strong>No. </strong>
                            </th>
                            <th width="80%">
                                <strong>Number</strong>
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let contact of contactUsList; index as contactPosition">
                                <td width="20%">{{contactPosition+1}}.</td>
                                <td width="80%"><a href="tel:+{{contact.number}}">{{contact.number}}</a></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="d-flex justify-content-center">
                <button type="button" class="btn btn-rhino continue" (click)="hideContactUs()">
                    OK
                </button>
            </div>


        </div>

    </div>
</ng-template>
