import { LayoutMobileMainComponent } from '../layout-mobile/layout-mobile-main/layout-mobile-main.component'
import { LayoutMobileHomeComponent } from '../layout-mobile/layout-mobile-home/layout-mobile-home.component'
import { LayoutMobileHomeMainComponent } from '../layout-mobile/layout-mobile-home-main/layout-mobile-home-main.component';
import { Error404Component } from './pages/error404/error404.component';
import { Error500Component } from './pages/error500/error500.component';
import { TermComponent } from './pages/terms/term.component';
import { ClaimComponent } from './pages/claim/claim.component';
import { AboutIhsanComponent } from './pages/about-ihsan/about-ihsan.component';
import { LayoutMobileTncComponent } from '../layout-mobile/layout-mobile-tnc/layout-mobile-tnc.component';
import { AcceptTncModule } from './accept-tnc/accept-tnc.module';
import { WelcomeTermComponent } from './accept-tnc/welcome-term/welcome-term.component';


export const routes = [   
    {
        path: '',
        component: LayoutMobileTncComponent,
        children: [
            { path: 'tnc', loadChildren: () => import('./accept-tnc/accept-tnc.module').then(m => m.AcceptTncModule) },
            { path: 'welcome-tnc', component: WelcomeTermComponent },
        ]
    },
    {
        path: '',
        component: LayoutMobileHomeComponent,
        children: [
            { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) }, 
            { path: 'about-ihsan', component: AboutIhsanComponent }
        ]
    },
    {
        path: '',
        component: LayoutMobileHomeMainComponent,
        children:[
            { path: 'main', loadChildren: () => import('./home-main/home-main.module').then(m => m.HomeMainModule) },            
        ]
    },

    {
        path: '',
        component: LayoutMobileMainComponent,
        children: [              
            { path: 'term-condition', component: TermComponent },
            { path: 'ecert', loadChildren: () => import('./ecert/ecert.module').then(m => m.EcertModule) },
            { path: 'application', loadChildren: () => import('./application/application.module').then(m => m.ApplicationModule) },
            { path: 'claim', component: ClaimComponent }
        ]
    },
   
    

    // Not lazy-loaded routes        
    { path: '404', component: Error404Component },
    { path: '500', component: Error500Component },    

    // Not found
    { path: '**', redirectTo: 'tnc' }

];
