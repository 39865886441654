import { Component } from '@angular/core';



@Component({
    selector: 'app-layout-mobile-tnc',
    templateUrl: './layout-mobile-tnc.component.html',
    styleUrls: ['./layout-mobile-tnc.component.scss']
})
export class LayoutMobileTncComponent {
    constructor() {}   
}


