import { Component } from "@angular/core";

@Component({
    selector: 'app-amgen-contact',
    templateUrl: './amgen-contact.component.html',
    styleUrls: ['./amgen-contact.component.scss']
})
export class AmgenContact {

    contacts: {address:string, phone:string}[] = [
        {
            address: '408, Taman Saujana, Jalan Kamunting, Kamunting, 34600, Taiping, Perak',
            phone: '05-807 2254'
        },
        {
            address: '9, Jalan Todak 1, Pusat Bandar Sunway, 13700 Perai, Pulau Pinang',
            phone: '04-397 5085'
        },
        {
            address: '211, Jalan Tun Sambanthan, Kuala Lumpur Sentral, 50470 Kuala Lumpur, Wilayah Persekutuan Kuala Lumpur',
            phone: '03-2268 3333'
        },
        {
            address: '9, Jalan Cempaka 1/1, Bandar Amanjaya, 06000 Sungai Petani, Kedah',
            phone: '04-442 8218'
        },
        {
            address: 'No 142-L (Level 1 & 2), Burmah Place, Jalan Burma, George Town, 10050 George Town, Pulau Pinang',
            phone: ''
        },
        {
            address: 'Menara Kurnia, No. 9, Jalan PJS 8/9, Petaling Jaya, 46150, Selangor, 46150 Petaling Jaya',
            phone: '03-7875 0028'
        },
        {
            address: '33, Sistem Lingkaran-Lebuhraya Kajang, Kawasan Perindustrian Ria, 43500 Semenyih, Selangor',
            phone: '013-208 1852'
        },
        {
            address: 'B344, Jalan Beserah, 25300, Kuantan, Pahang, Malaysia, 25300 Kuantan',
            phone: '09-566 3012'
        },
        {
            address: 'No. 110, Jalan Ros Merah 2/17, Johor Jaya, 81100 Johor Bahru, Johor',
            phone: '07-355 2970'
        },
        {
            address: 'Jalan Melaka Raya 3, Taman Melaka Raya, 75000 Melaka',
            phone: ''
        },
        {
            address: '26, Jalan Sultan Mahmud, 20400 Kuala Terengganu, Terengganu',
            phone: '09-631 8511'
        },
        {
            address: '12, Jalan Padi Huma 1, Bandar Baru Uda, 81200 Johor Bahru, Johor',
            phone: '010-546 1939'
        },
        {
            address: 'No 20 Ground Floor Bazaar MPS Greenwood, Taman Greenwood, 68100 Batu Caves, Selangor',
            phone: '012-345 3484'
        },
        {
            address: 'No. 1, Tingkat 8, Bangunan Amdb, Jalan Lumut, Kuala Lumpur 50400, Kuala Lumpur',
            phone: '03-4050 1702'
        },

        
    ]

}