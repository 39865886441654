<div class="d-flex flex-column align-items-center">
    <img class="mb-2" src="assets/img/insurance/insan_logo_v2/insan-official-logo-dark-font.png" alt="">    
</div>


<div class="bg-rhino title text-secondary text-center py-1 mb-2">
    TERMA & SYARAT-SYARAT
</div>

<section class="bg-cultured" [ngClass]="{'readOnly':readOnly}">   

    <div class="p-1 mb-4 terms">

        <div class="row mb-2">
            <div class="col-12">
                SKIM INSURANS AM SELAGOR (INSAN) adalah sebuah inisiatif yang dianjurkan oleh Kerajaan Negeri Selangor dan dikendalikan oleh WAVPAY FINTECH HOLDING SDN BHD (“WAVPAY) dengan Kerjasama AMGENERAL INSURANCE BERHAD/TAKAFUL IKHLAS (“RAKAN KONGSI INSURANS/TAKAFUL”).
            </div>
        </div>

        <div class="row mb-2">
            <div class="col-12">
                Dengan memilih dan mengaktifkan polisi insurans/takaful dibawah SKIM INSAN, anda telah bersetuju, dengan kebenaran, serta maklum bahawa anda tertakluk kepada segala terma dan syarat yang dinyatakan dan/atau sebarang perubahan yang berlaku seperti:
            </div>
        </div>

        <div class="row mb-2">
            <div class="col-1">
                1
            </div>
            <div class="col-11">
                TEMPOH TAWARAN DAN PERLINDUNGAN – polisi insurans dibawah SKIM INSAN yang ditawarkan adalah untuk tempoh aktif selama dua belas (12) bulan bermula dari 1 Oktober 2022 sehingga 30 September 2023 sahaja (Tempoh Tawaran) dan hanya terhad kepada enam (6) juta rakyat Negeri Selangor. Permohonan ditutup selepas mencapai jumlah pendaftaran yang dihadkan atau selepas tamat Tempoh Tawaran. Tempoh perlindungan dibawah polisi insurans/takaful adalah selama tempoh aktif iaitu dua belas (12) bulan.
            </div>
        </div>

        <div class="row mb-2">
            <div class="col-1">
                2
            </div>
            <div class="col-11">
                PERUNTUKAN PERLINDUNGAN  – polisi terbahagi kepada dua kategori:
                <br>
                <span class="pr-1">a)</span>	Konvensional (Bukan beragama Islam) – 3 juta polisi sahaja
                <br>
                <span class="pr-1">b)</span>	Takaful (Beragama Islam) – 3 juta polisi sahaja
            </div>
        </div>

        <div class="row mb-2">
            <div class="col-1">
                3
            </div>
            <div class="col-11">
                KELAYAKAN PERLINDUNGAN  – perlindungan berdasarkan polisi insurans/takaful dibawah SKIM INSAN hanya layak dimohon oleh individu berusia 30 hari sehingga 80 tahun, yang lahir di Negeri Selangor dengan kod di atas kad pengenalan berangka 10 dan/atau memiliki alamat terkini di dalam kad pengenalan yang berdaftar di Negeri Selangor semasa didalam tempoh perlindungan ditawarkan, atau pengundi yang berdaftar untuk mengundi di Negeri Selangor serta tertakluk kepada syarat dan jenis pekerjaan yang terkecuali seperti dinyatakan oleh Rakan Kongsi Insurans/Takaful. 
            </div>
        </div>

        <div class="row mb-2">
            <div class="col-1">
                4
            </div>
            <div class="col-11">
                PEKERJAAN TERKECUALI – pekerjaan yang berbahaya/terlibat dengan persekitaran berbahaya tidak terlindung dibawah SKIM INSAN melainkan kemalangan yang melayakkan sebarang tuntutkan dilakukan berlaku ketika diluar waktu bertugas seperti yang ditunjukkan dalam jadual di bawah:
            </div>
        </div>

        <div class="row mb-2">
            <div class="col-11 offset-1">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>
                                <strong>Jenis Pekerjaan</strong>
                            </th>
                            <th>
                                <strong>Perlindungan</strong>
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td>Penghibur Akrobat/Sarkas</td>
                            <td>Di luar waktu bertugas</td>
                        </tr>

                        <tr>
                            <td>Tentera Darat/Tentera Laut/Tentera Udara/Anggota Tentera </td>
                            <td>Di luar waktu bertugas</td>
                        </tr>

                        <tr>
                            <td>Pelayan Bar</td>
                            <td>Di luar waktu bertugas</td>
                        </tr>

                        <tr>
                            <td>Tukang Besi</td>
                            <td>Di luar waktu bertugas</td>
                        </tr>

                        <tr>
                            <td>Ahli Bomba</td>
                            <td>Di luar waktu bertugas</td>
                        </tr>

                        <tr>
                            <td>Nelayan</td>
                            <td>Di luar waktu bertugas</td>
                        </tr>

                        <tr>
                            <td>Ejen Ramalan (contoh: MAGNUN & Ejen TOTO) </td>
                            <td>Tidak Dilindungi</td>
                        </tr>

                        <tr>
                            <td>Tukang Emas</td>
                            <td>Di luar waktu bertugas</td>
                        </tr>

                        <tr>
                            <td>Pegawai Perhubungan Tetamu (Pusat Hiburan)</td>
                            <td>Di luar waktu bertugas</td>
                        </tr>

                        <tr>
                            <td>Joki – Lumba Kuda</td>
                            <td>Di luar waktu bertugas</td>
                        </tr>

                        <tr>
                            <td>Anggota Penyelamat</td>
                            <td>Di luar waktu bertugas</td>
                        </tr>

                        <tr>
                            <td>Penternak Ternakan Hidup</td>
                            <td>Di luar waktu bertugas</td>
                        </tr>

                        <tr>
                            <td>Pemberi Pinjaman Wang (Berlesen)</td>
                            <td>Tidak Dilindungi</td>
                        </tr>

                        <tr>
                            <td>Tukang Cat (Atas 30 kaki atau 10 tingkat)</td>
                            <td>Tidak Dilindungi</td>
                        </tr>

                        <tr>
                            <td>Juruterbang</td>
                            <td>Di luar waktu bertugas</td>
                        </tr>

                        <tr>
                            <td>Polis</td>
                            <td>Di luar waktu bertugas</td>
                        </tr>

                        <tr>
                            <td>Ahli Sukan Profesional</td>
                            <td>Di luar waktu bertugas</td>
                        </tr>

                        <tr>
                            <td>Pekerja Kuari (Tidak meletup)</td>
                            <td>Di luar waktu bertugas</td>
                        </tr>

                        <tr>
                            <td>Pengawal Keselamatan</td>
                            <td>Di luar waktu bertugas</td>
                        </tr>

                        <tr>
                            <td>Kru Kapal/kelasi/Pemunggah Barang-barang</td>
                            <td>Di luar waktu bertugas</td>
                        </tr>

                        <tr>
                            <td>Penganggur (Selain daripada akibat pemberhentian atau sabatikal)</td>
                            <td>Tidak Dilindungi</td>
                        </tr>

                        <tr>
                            <td>Perniagaan/Aktiviti Haram/Tidak Berlesen</td>
                            <td>Tidak Dilindungi</td>
                        </tr>

                        <tr>
                            <td>Pelayan (Selain Restoran)</td>
                            <td>Tidak Dilindungi</td>
                        </tr>                                
                    </tbody>
                </table>
            </div>
        </div>


        <div class="row mb-2">
            <div class="col-1">
                5
            </div>
            <div class="col-11">
                Sebarang pengisytiharan maklumat yang dibuat oleh anda untuk mengaktifkan perlindungan dibawah polisi insurans/takaful adalah benar dan tepat. WAVPAY tidak akan bertanggungjawab keatas sebarang liabiliti, yang mungkin timbul disebabkan oleh maklumat yang tidak tepat, yang telah diberikan dan diserahkan oleh anda untuk diri sendiri atau bagi pihak penama anda kepada Rakan Kongsi Insurans/Takaful kami.
            </div>
        </div>

        <div class="row mb-2">
            <div class="col-1">
                6
            </div>
            <div class="col-11">
                Tuntutan yang dilakukan berdasarkan polisi insurans/takaful hendaklah dibuat terus kepada Rakan Kongsi Insurans/Takaful kami untuk diproses mengikut cara yang ditetapkan. WAVPAY tidak akan memproses sebarang permohonan yang dibuat oleh anda bagi tujuan tuntutan untuk diserahkan kepada Rakan Kongsi Insurans/Takaful kami. 
            </div>
        </div>

        <div class="row mb-2">
            <div class="col-1">
                7
            </div>
            <div class="col-11">
                Anda akan bertanggungjawab untuk mematuhi semua terma dan syarat polisi insurans/takaful, termasuk polisi perlindungan am (atau sebarang nama atau definisi, yang membawa maksud yang sama), yang dinyatakan oleh Rakan Kongsi Insurans/Takaful kami untuk memastikan perlindungan dibawah polisi insurans/takaful terus sah untuk dikuatkuasakan. Anda bertanggungjawab untuk menyemak terma dan syarat.
            </div>
        </div>  

        <div class="row mb-2">
            <div class="col-1">
                8
            </div>
            <div class="col-11">
                Anda berisiko untuk dikecualikan daripada perlindungan dibawah polisi insurans/takaful jika anda memilih untuk menamatkan akaun WAVPAY E-WALLET anda semasa didalam tempoh perlindungan.
            </div>
        </div>  


        <div class="row mb-2">
            <div class="col-1">
                9
            </div>
            <div class="col-11">
                Perlindungan dibawah polisi insurans/takaful secara jelas mengecualikan kematian atau sebarang kecederaan/kecacatan yang secara langsung atau tidak langsung disebabkan oleh atau berkaitan dengan mana-mana yang berikut:-
                <div class="my-2">
                    <span class="pr-1">a)</span>Peperangan, pencerobohan, tindakan musuh asing (sama ada perang yang diisytiharkan atau tidak), perang saudara, pemberontakan, revolusi, pergolakkan atau rampasan kuasa atau tindakan ketenteraan;
                </div>

                <div class="my-2">
                    <span class="pr-1">b)</span>Ketidakwarasan, bunuh diri (sama waras atau tidak) atau sebarang percubaan acaman, kecederaan yang disengajakan oleh diri sendiri; 
                </div>

                <div class="my-2">
                    <span class="pr-1">c)</span>Sebarang bentuk penyakit, jangkitan atau parasit termasuk malaria, demam denggi dan Sindrom Kurang Upaya Melawan Penyakit (AIDS) atau AIDS Related Complex (ARC) Japanese Encephalitis;
                </div>

                <div class="my-2">
                    <span class="pr-1">d)</span>Bersalin, keguguran, kehamilan atau sebarang komplikasi daripadanya;
                </div>

                <div class="my-2">
                    <span class="pr-1">e)</span>Provokasi pembunuhan atau serangan;
                </div>

                <div class="my-2">
                    <span class="pr-1">f)</span>Semasa dalam perjalanan di atas pesawat atau kapal sebagai anak kapal, kecuali hanya sebagai penumpang yang membayar tambang untuk menaiki pesawat atau kapal yang dilesenkan untuk perkhidmatan membawa penumpang;
                </div>

                <div class="my-2">
                    <span class="pr-1">g)</span>Semasa melakukan atau cuba melakukan apa-apa tindakan yang menyalahi undang-undang;
                </div>

                <div class="my-2">
                    <span class="pr-1">h)</span>Semasa menyertai mana-mana sukan professional, seni mempertahankan diri atau tinju, aktiviti udara termasuk payung terjun dan luncur udara, aktiviti di bawah air melebihi kedalaman 50 meter, pendakian gunung secara professional yang melibatkan penggunaan tali atau alat bantuan mekanikal;
                </div>

                <div class="my-2">
                    <span class="pr-1">i)</span>Perlumbaan (selain menggunakan kaki), mengatur rentak (pace-making), ujian kelajuan atau ketahanan; 
                </div>

                <div class="my-2">
                    <span class="pr-1">j)</span>Pengionan, radiasi atau pencemaran radioaktif daripada apa-apa bahan buangan nuklear atau dari pembakaran bahan api nuklear atau bahan senjata nuklear;
                </div>

                <div class="my-2">
                    <span class="pr-1">k)</span>Memandu/Menunggang tanpa lesen yang sah;
                </div>

                <div class="my-2">
                    <span class="pr-1">l)</span>(i) Asbestos, atau (ii) sebarang kecederaan atau kerosakan berkaitan asbestos sebenar atau yang didakwa melibatkan penggunaan, kehadiran, kewujudan, pengesanan, penyingkiran, penghapusan, pengelakan asbestos atau pendedahan atau potensi pendedahan kepada asbestos;
                </div>

                <div class="my-2">
                    <span class="pr-1">m)</span>Pengionan daripada atau pencemaran oleh radioaktif daripada mana-mana bahan api nuklear atau daripada mana-mana sisa nuklear atau daripada pembakaran bahan api nuklear; (ii) Bahan radioaktif, toksik, bahan letupan atau lain-lain yang berbahaya atau mencemarkan mana-mana pemasangan nuklear, reaktor atau pemasangan nuklear lain atau komponen nuklearnya; (iii) Sebarang senjata perang yang menggunakan kandungan atom atau nuklear dan/atau peleburan atau lain-lain seperti tindak balas atau daya radioaktif atau jirim;
                </div>

                <div class="my-2">
                    <span class="pr-1">n)</span>Sebarang tindakan keganasan. Untuk tujuan fasal ini, keganasan bermakna satu tindakan, atau tindakan-tindakan mana-mana individu, atau kumpulan individu, yang dilakukan untuk tujuan politik, agama, ideologi atau tujuan seumpamanya dengan niat untuk mempengaruhi mana-mana kerajaan dan/atau untuk membuatkan orang awam atau mana-mana bahagian awam berada dalam ketakutan;
                </div>

                <div class="my-2">
                    <span class="pr-1">o)</span>Sebarang kecacatan fikizal, mental atau uzur, sebarang jenis sawan yang sedia ada.
                </div>
            </div>
        </div>

        <div class="row mb-2">
            <div class="col-1">
                10
            </div>
            <div class="col-11">
                WAVPAY boleh meminda atau mengubah terma dan syarat secara unilateral tanpa sebarang notis awal.
            </div>
        </div>

        <div class="row mb-2">
            <div class="col-1">
                11
            </div>
            <div class="col-11">
                Bagi tujuan polisi insurans dibawah SKIM INSAN, WAVPAY bertindak sebagai pemproses data untuk dan bagi pihak Negeri Selangor, sebagai pihak berkepentingan SKIM INSAN dan pengguna data. Sebarang maklumat dan data anda, yang dikemukakan untuk tujuan pengaktifan polisi insurans dibawah SKIM INSAN akan disifatkan sebagai dikumpul untuk dan bagi pihak Negeri Selangor. Melainkan jika dinyatakan sebaliknya, sebarang maklumat dan data lain yang didedahkan untuk tujuan di sini adalah tertakluk kepada dasar privasi WAVPAY, yang mematuhi Akta Perlindungan Data Peribadi 2010.
            </div>
        </div>

        <div class="row mb-2">
            <div class="col-1">
                12
            </div>
            <div class="col-11">
                WAVPAY bukan penyedia insurans/takaful tetapi hanya pembekal perkhidmatan, yang memudahkan anda sebagai pengguna untuk mengakses polisi insurans/takaful yang ditawarkan oleh Rakan Kongsi Insurans/Takaful kami. Tiada apa-apa dibawah perjanjian ini yang boleh memberi gambaran bahawa WAVPAY dengan sendirinya menawarkan insurans/takaful yang mungkin melanggar Akta Insurans 1963.
            </div>
        </div>

        <div class="row mb-2">
            <div class="col-1">
                13
            </div>
            <div class="col-11">
                Anda boleh menghubungi Rakan Kongsi Insurans/Takaful kami untuk sebarang pertanyaan mengenai perlindungan atau polisi insurans/takaful, khususnya mengenai Kemalangan Diri;

                <address class="mt-2">
                    <div class="font-weight-bold">AMGENERAL INSURANCE BERHAD</div>
                    <div>(Dulu dikenali sebagai Kurnia Insurans (Malaysia) Berhad dan ahli</div>
                    <div>Kumpulan AMBANK)</div>
                    <div>Menara Kurnia</div>
                    <div>No. 9, Jalan PJS 8/9, </div>
                    <div>46150 Petaling Jaya, </div>
                    <div>PO Box 8607, 46792 Petaling Jaya, </div>
                    <div>Selangor Darul Ehsan, Malaysia</div>
                </address>

                <div class="row">
                    <div class="col-3">No. Tel.</div>
                    <div class="col-9">: <a href="tel:+1300803030 ">1300 80 3030</a></div>

                    <div class="col-3">No. Faks</div>
                    <div class="col-9">: <a href="tel:+60321713030 ">+603-2171 3030</a></div>

                    <div class="col-3">E-mel</div>
                    <div class="col-9">: <a href="mailto:amassurance-general@ambankgroup.com">amassurance-general@ambankgroup.com</a></div>

                    <div class="col-3">Laman Web</div>
                    <div class="col-9">: <a class="text-primary" (click)="goToAmAssurance()">www.amassurance.com.my</a></div>
                </div>
                <br>

                <address class="mt-2">
                    <div class="font-weight-bold">Takaful Ikhlas General Berhad</div>
                    <div>(200201025412) (593075-U)</div>
                    <div>9th Floor, IKHLAS Point, Tower 11A, Avenue 5,</div>
                    <div>Bangsar South, No. 8, Jalan Kerinchi,</div>
                    <div>59200, Kuala Lumpur </div>
                </address>

                <div class="row">
                    <div class="col-3">No. Tel.</div>
                    <div class="col-9">: <a href="tel:+0327239999 ">+03-2723 9999 </a></div>

                    <div class="col-3">No. Faks</div>
                    <div class="col-9">: <a href="tel:+0327239696 ">+03-2723 9696 </a></div>

                    <div class="col-3">E-mel</div>
                    <div class="col-9">: <a href="mailto:ikhlascare@takaful-ikhlas.com.my">ikhlascare@takaful-ikhlas.com.my</a></div>

                    <div class="col-3">Laman Web</div>
                    <div class="col-9">: <a class="text-primary" (click)="goToTakaful()">www.takaful-ikhlas.com.my</a></div>
                </div>

            </div>
        </div>

        <div class="row mb-2">
            <div class="col-1">
                14
            </div>
            <div class="col-11">
                Untuk mengelakkan sebarang keraguan, rujukan Rakan Kongsi Insurans/Takaful apabila disebut dalam Terma dan Syarat ini tidak boleh ditafsirkan sebagai perkongsian di bawah Akta Perkongsian 1961. Oleh itu, sebarang kewajipan yang dikenakan oleh Rakan Kongsi Insurans/Takaful untuk penyediaan perlindungan tidak boleh dilanjutkan kepada WAVPAY atas sebarang sebab sekalipun.
            </div>
        </div>

    </div>

    <!-- <div class="row">
        <div class="col-10 offset-1">
            <table class="table">
                <thead>
                    <tr>
                        <th class="bg-rhino text-secondary text-center border-right border-secondary">Kelayakan</th>
                        <th class="bg-rhino text-secondary text-center">Perlindungan</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="px-3 col-6">
                            Individu yang memegang kad pengenalan beralamat di Negeri Selangor
                        </td>
                        <td class="px-3 col-6 text-center">
                            RM 10,000
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div> -->

</section>