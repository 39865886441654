import { Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { excludedOccupationsGrouped } from '@models/occupations';
import { contactNum } from '@models/contactus';
import { PolicyService, policyType } from '@services/policy.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-layout-bottom-nav',
    templateUrl: './bottom-nav.component.html',
    styleUrls: ['./bottom-nav.component.scss']
})
export class LayoutBottomNavComponent implements OnInit, OnDestroy{

    @ViewChild('moreIcon') moreIcon;
    @ViewChild('exemptedJobTemplate') exemptedJobTemplate: TemplateRef<any>;
    @ViewChild('faqTemplate') faqTemplate: TemplateRef<any>;
    @ViewChild('contactUsTemplate') contactUsTemplate: TemplateRef<any>;

    exemptedJobModalRef: BsModalRef;
    faqModalRef: BsModalRef;
    contactUsModalRef: BsModalRef;

    moreMenuVisible = false;

    menuStyle = {
        left: '10px',
        right: 'auto',
        bottom: 'auto'
    }

    @HostListener('window:resize', ['$event'])
    onOrientationChange(event) {
        this.moreMenuVisible = false;
    }

    @HostListener('document:click', ['$event.target'])
    public onPageClick(targetElement) {

        const clickedInside = this.elementRef.nativeElement.contains(targetElement);
        if (!clickedInside) {
            this.moreMenuVisible = false;
        }
    }

    showAddApplicantMenu = false;

    policyUpdateSubscription: Subscription;

    constructor(
        private elementRef: ElementRef ,
        private modalService: BsModalService,
        private policyService: PolicyService,
        @Inject(DOCUMENT) private document: Document
    ) {}

    ngOnInit(): void {

        const policies:policyType[] = this.policyService.getPolicies()
        if(policies.length){
            this.showAddApplicantMenu = true;
        }

        this.policyUpdateSubscription = this.policyService.onPoliciesUpdated
        .subscribe( (policies:policyType[]) => {
            if(policies.length){
                this.showAddApplicantMenu = true;
            }
        } )


    }



    showMore(event) {

        event.preventDefault();

        this.moreMenuVisible = !this.moreMenuVisible

        if (this.moreMenuVisible) {
            const moreButtonOffsetBottom =
                this.moreIcon.nativeElement.offsetParent.offsetHeight -
                this.moreIcon.nativeElement.offsetTop -
                this.moreIcon.nativeElement.offsetHeight;

            if (screen.width > 430) {

                this.menuStyle.left = `${event.target.offsetLeft - 82}px`;
                this.menuStyle.right = 'auto';
            }
            else {

                this.menuStyle.left = 'auto';
                this.menuStyle.right = '20px';
            }

            this.menuStyle.bottom = `${moreButtonOffsetBottom + 20}px`

        }

    }

    sortOccupationsByName(a,b){
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    }

    showExemptedJobList() {
        this.exemptedJobModalRef = this.modalService.show(this.exemptedJobTemplate, {
            class: 'modal-lg modal-dialog-centered',
            ignoreBackdropClick: true,
            backdrop: 'static',
            keyboard: false
        })
    }


    hideExemptedJobList() {
        this.exemptedJobModalRef.hide()
    }

    showFAQ() {
        this.faqModalRef = this.modalService.show(this.faqTemplate, {
            class: 'modal-lg modal-dialog-centered',
            ignoreBackdropClick: true,
            backdrop: 'static',
            keyboard: false
        })
    }


    hideFAQ() {
        this.faqModalRef.hide()
    }

    showContactUs() {
        this.contactUsModalRef = this.modalService.show(this.contactUsTemplate, {
            class: 'modal-lg modal-dialog-centered',
            ignoreBackdropClick: true,
            backdrop: 'static',
            keyboard: false
        })
    }


    hideContactUs() {
        this.contactUsModalRef.hide()
    }

    navigateAway() {
        this.moreMenuVisible = !this.moreMenuVisible
    }

    ngOnDestroy(): void {
        this.policyUpdateSubscription.unsubscribe()
    }

    get excludedOutOfWorkingHours (){
        return excludedOccupationsGrouped.outOfWorkingHours.sort(this.sortOccupationsByName)
    }

    get excludedUncovered (){
        return excludedOccupationsGrouped.uncovered.sort(this.sortOccupationsByName)
    }

    get contactUsList (){
        return contactNum;
    }

    goToProgramInsan():void {
        // window.open("https://www.programinsan.com/tuntutan", '_blank');
        this.document.location.href = 'https://www.programinsan.com/tuntutan';
    }


}
