<div class="container-fluid px-4">

    <div class="row">
        <div class="col-12">

            <form action="#" *ngIf="applicationForm" [formGroup]="applicationForm">
                <!-- Name -->
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="name" class="text-black input-label">Nama</label>
                            <input type="text" id="name" name="name" class="form-control" formControlName="name" />
                            <span *ngIf="
                                applicationForm.get('name').touched &&
                                applicationForm.get('name').errors &&
                                applicationForm.get('name').errors['required']" class="help-block mt-1 error-message">
                                Name is required
                            </span>
                        </div>
                    </div>
                </div>

                <!-- Ic Number -->
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="icNumber" class="text-black input-label">No. Kad Pengenalan</label>
                            <input type="text" id="icNumber" name="icNumber" class="form-control"
                                formControlName="icNumber" (input)="updateGenderOnIcChange()"
                                placeholder="xxxxxx-xx-xxxx" [textMask]="{mask: mask}" />
                            <span *ngIf="
                            applicationForm.get('icNumber').touched &&
                            applicationForm.get('icNumber').errors &&
                            applicationForm.get('icNumber').errors['required']" class="help-block mt-1 error-message">
                                Ic number is required
                            </span>
                            <span *ngIf="
                            applicationForm.get('icNumber').touched &&
                            applicationForm.get('icNumber').errors &&
                            applicationForm.get('icNumber').errors['invalidIcNumber']"
                                class="help-block mt-1 error-message">
                                Invalid Ic Number
                            </span>
                            <span *ngIf="
                            applicationForm.get('icNumber').touched &&
                            applicationForm.get('icNumber').errors &&
                            applicationForm.get('icNumber').errors['icNumberDuplicate']"
                                class="help-block mt-1 error-message">
                                Duplicate Ic Number
                            </span>
                            <span *ngIf="
                            applicationForm.get('icNumber').touched &&
                            applicationForm.get('icNumber').errors &&
                            applicationForm.get('icNumber').errors['icNumberExist']"
                                class="help-block mt-1 error-message">
                                Ic Number Already Registered
                            </span>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="religion">
                                Agama
                            </label>
                            <select id="religion" formControlName="religion" class="form-control">
                                <option value="muslim">Muslim</option>
                                <option value="non_muslim">Non-Muslim</option>
                            </select>
                        </div>
                    </div>
                </div>

                <!-- Address 1 -->
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="address1" class="text-black input-label">
                                Alamat <span class="text-blood">(Mesti mengikut kad pengenalan)</span>
                            </label>
                            <input type="text" id="address1" name="address1" class="form-control"
                                formControlName="address1" />
                            <span *ngIf="
                                applicationForm.get('address1').touched &&
                                applicationForm.get('address1').errors &&
                                applicationForm.get('address1').errors['required']"
                                class="help-block mt-1 error-message">
                                Address is required
                            </span>
                        </div>
                    </div>
                </div>

                <!-- Address 2 -->
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="address2" class="text-black input-label">Alamat</label>
                            <input type="text" id="address2" name="address2" class="form-control"
                                formControlName="address2" />
                        </div>
                    </div>
                </div>

                <div class="row">

                    <!-- Postcode -->
                    <div class="col-6">
                        <div class="form-group">
                            <label for="postcode" class="text-black input-label">Poskod</label>
                            <input type="text" id="postcode" name="postcode" class="form-control"
                                formControlName="postCode" (input)="searchPostCode($event)" />
                            <span *ngIf="
                                applicationForm.get('postCode').touched &&
                                applicationForm.get('postCode').errors &&
                                applicationForm.get('postCode').errors['required']
                              " class="help-block mt-1 error-message">Postcode is required</span>
                            <span *ngIf="
                                applicationForm.get('postCode').errors &&
                                applicationForm.get('postCode').errors['postCodeInvalid']
                              " class="help-block mt-1 error-message">Postcode is invalid</span>
                        </div>
                    </div>

                    <!-- City -->
                    <div class="col-6">
                        <div class="form-group">
                            <label for="city" class="text-black input-label">Bandar</label>
                            <input type="text" id="city" name="city" class="form-control" formControlName="city" />
                        </div>
                    </div>
                </div>

                <div class="row">
                    <!-- State -->
                    <div class="col-6">
                        <div class="form-group">
                            <label for="state" class="text-black input-label">Negeri</label>
                            <!-- <select
                                name="state"
                                id="state"
                                class="form-control"
                                formControlName="state"
                            >
                                <option value="johor">Johor</option>
                                <option value="kedah">Kedah</option>
                                <option value="kelantan">Kelantan</option>
                                <option value="kuala lumpur">
                                    Kuala Lumpur
                                </option>
                                <option value="labuan">Labuan</option>
                                <option value="melaka">Melaka</option>
                                <option value="negeri sembilan">
                                    Negeri Sembilan
                                </option>
                                <option value="pahang">Pahang</option>
                                <option value="penang">Penang</option>
                                <option value="perak">Perak</option>
                                <option value="perlis">Perlis</option>
                                <option value="putrajaya">Putrajaya</option>
                                <option value="selangor">Selangor</option>
                                <option value="sabah">Sabah</option>
                                <option value="sarawak">Sarawak</option>
                                <option value="terengganu">Terengganu</option>
                            </select> -->
                            <input type="text" name="state" id="state" formControlName="state" class="form-control" />
                        </div>
                    </div>

                    <!-- Gender -->
                    <div class="col-6">
                        <div class="form-group">
                            <label for="gender" class="text-black input-label">Jantina</label>
                            <select name="gender" id="gender" class="form-control" formControlName="gender">
                                <option value="male">Lelaki</option>
                                <option value="female">Perempuan</option>
                            </select>
                            <span *ngIf="
                            applicationForm.get('gender').touched &&
                            applicationForm.get('gender').errors &&
                            applicationForm.get('gender').errors['required']" class="help-block mt-1 error-message">
                                Gender is required
                            </span>
                        </div>
                    </div>
                </div>

                <!-- Email -->
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="email" class="text-black input-label">E-mel (Optional)</label>
                            <input type="email" id="email" name="email" class="form-control" formControlName="email" />
                            <span *ngIf="
                                applicationForm.get('email').touched &&
                                applicationForm.get('email').errors &&
                                applicationForm.get('email').errors['required']" class="help-block mt-1 error-message">
                                Email is required
                            </span>
                            <span *ngIf="
                                applicationForm.get('email').touched &&
                                applicationForm.get('email').errors &&
                                applicationForm.get('email').errors['email']" class="help-block mt-1 error-message">
                                Invalid email
                            </span>
                        </div>
                    </div>
                </div>

                <!-- Phone -->
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="phoneNumber" class="text-black input-label">No. Telefon (Optional)</label>
                            <input type="phoneNumber" id="phoneNumber" name="phoneNumber" class="form-control"
                                formControlName="phoneNumber" />
                            <span *ngIf="
                                applicationForm.get('phoneNumber').touched &&
                                applicationForm.get('phoneNumber').errors &&
                                applicationForm.get('phoneNumber').errors['required']"
                                class="help-block mt-1 error-message">
                                Phone number required
                            </span>
                        </div>
                    </div>
                </div>

                <!-- Occupation -->
                <!-- <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="occupation" class="text-black input-label">Pekerjaan
                            </label>

                            <select name="occupation" id="occupation" class="form-control" formControlName="occupation"
                                (change)="checkExemptedOccupation($event)">  
                                <option [value]="undefined">Sila Pilih Pekerjaan</option>
                                <ng-container *ngFor="let occ of occupations">                                    
                                    <option *ngIf="occ.code !== 'OCC000113'" [value]="occ.code">
                                        {{ occ.name }}
                                    </option>
                                </ng-container>

                                <option value="OCC000113">PENGANGGUR-LAIN-LAIN</option>
                              
                            </select>

                            <span *ngIf="
                                applicationForm.get('occupation').touched &&
                                applicationForm.get('occupation').errors &&
                                applicationForm.get('occupation').errors['required']"
                                class="help-block mt-1 error-message">
                                Occupation is required
                            </span>
                        </div>
                    </div>
                </div> -->

                <!-- Relationship -->
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="relationship" class="text-black input-label">Hubungan dengan Primary</label>
                            <select name="relationship" id="relationship" class="form-control"
                                formControlName="relationship">
                                <option value="Self">Diri Sendiri</option>
                                <option value="Father">Ayah</option>
                                <option value="Mother">Ibu</option>
                                <option value="Son">Anak Lelaki</option>
                                <option value="Daughter">Anak Perempuan</option>
                                <option value="Husband">Suami</option>
                                <option value="Wife">Isteri</option>
                                <!-- <option value="Brother">Abang</option>
                                <option value="Sister">Kakak</option>
                                <option value="Younger brother">Adik Lelaki</option>
                                <option value="Younger sister">Adik Perempuan</option>
                                <option value="Uncle">Pakcik</option>
                                <option value="Auntie">Makcik</option>
                                <option value="Nephew">Anak saudara lelaki</option>
                                <option value="Niece">Anak saudara perempuan</option>
                                <option value="Guardian">Penjaga</option>
                                <option value="Ward">Anak Jagaan</option> -->
                            </select>
                            <span *ngIf="
                            applicationForm.get('relationship').touched &&
                            applicationForm.get('relationship').errors &&
                            applicationForm.get('relationship').errors['required']"
                                class="help-block mt-1 error-message">
                                Relationship is required
                            </span>
                        </div>
                    </div>
                </div>

                <ng-container formGroupName="icPhoto">
                    <div class="border-top border-bottom py-3 my-3">
                        <app-application-uploadic></app-application-uploadic>
                    </div>

                </ng-container>

                <div class="row">
                    <div class="col-12">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="agreeToc"
                                id="agreeToc" formControlName="agreeToc" />
                            <label class="form-check-label agreement" for="agreeToc">
                                Saya dengan ini mengesahkan bahawa:<br>
                                <span *ngIf="
                                    applicationForm.get('agreeToc').touched &&
                                    applicationForm.get('agreeToc').errors &&
                                    applicationForm.get('agreeToc').errors['required']"
                                    class="help-block mt-1 error-message">
                                    User confirmation required<br>
                                </span>
                                •	Maklumat yang diberikan adalah benar dan tepat.<br>
                                •	Maklumat agama yang dimasukkan adalah benar dan tepat dan memahami bahawa perlindungan INSAN Takaful hanya layak untuk individu yang beragama Islam. Tuntutan tidak akan diproses sekiranya terdapat sebarang ketidaktepatan dengan maklumat yang diberikan. <br>
                                •	Sekiranya membuat pendaftaran untuk individu tanggungan, saya telah mendapat kebenaran dan kelulusan daripada individu tersebut untuk mendaftarkan nama mereka untuk Program INSAN dan saya mengesahkan bahawa maklumat yang diberikan adalah benar dan tepat.<br>
                                •	Saya telah membaca, memahami dan bersetuju dengan terma dan syarat.<br>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="row mb-5">
                    <div class="col-12 d-flex justify-content-center">
                        <button type="button" class="btn btn-rhino continue" (click)="continue()"
                            [ngClass]="{'disabled':!applicationForm.valid}"
                            [attr.aria-disabled]="!applicationForm.valid">
                            Tambah
                        </button>
                    </div>

                    <div class="col-12 d-flex justify-content-center">
                        <button type="button" class="btn btn-rhino continue" (click)="cancel()">
                            Batal
                        </button>
                    </div>
                </div>

            </form>

        </div>
    </div>
</div>