import { Component, OnInit } from "@angular/core";
import { FormGroup, FormGroupDirective } from "@angular/forms";

@Component({
    selector: 'app-application-uploadic',
    templateUrl: './application-uploadic.component.html',
    styleUrls: ['./application-uploadic.component.scss']
})
export class ApplicationUploadIC implements OnInit {
       
    frontImageSrc: string | ArrayBuffer;
    backImageSrc: string | ArrayBuffer;

    form: FormGroup;

    constructor(private rootFormGroup: FormGroupDirective) {}

    ngOnInit(): void {
        this.form = this.rootFormGroup.control;
        
    }


    imageInputClicked(event){
        event.target.value = null;
    }
   
    showPreviewFront(event: any): void {
       
        if (event.target.files && event.target.files[0]) {            

            const file = event.target.files[0];            
    
            const reader = new FileReader();
            
            reader.onload = (e) => {
                this.frontImageSrc = reader.result;                
                this.form.get('icPhoto.frontBase64').setValue(this.frontImageSrc)                
            }
    
            reader.readAsDataURL(file);
        }
    }

    showPreviewBack(event: any): void {
        
       
        if (event.target.files && event.target.files[0]) {            

            const file = event.target.files[0];            
    
            const reader = new FileReader();
            
            reader.onload = (e) => {
                this.backImageSrc = reader.result;                
                this.form.get('icPhoto.backBase64').setValue(this.backImageSrc)                
            }
    
            reader.readAsDataURL(file);
        }
    }

}