import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UtilService } from './util.service';

@Injectable({
    providedIn: 'root'
})
export class ApplicationFormsService {

    applicationForms: ApplicationFormsInterface[] = [];

    applicationFormExtra: FormGroup;

    applicationInfos: any[] = [];
    applicationExtraInfos: any;

    constructor(
        private utilService:UtilService
    ){}
    

    addApplication(type:string, applicationId:string = ''){        

        const id = Math.random().toString(36).slice(2)

        this.applicationForms.push({
            id,
            type,
            applicationId,
            active: true
        })         

        this.activateForm(id)
        
        
        return id;
    }

    updateForm(id, formData){
        const theForm = this.applicationForms.find( form => form.id == id )
        theForm.formData = formData;         
    }    

    deactivateAllForm(){
        this.applicationForms.forEach( form => form.active = false )
    }

    activateForm(id:string | number = 'PRIMARY'){

        this.deactivateAllForm()

        if(id === 'PRIMARY'){
            this.applicationForms.find( form => form.type === 'PRIMARY' ).active = true;
        }
        else{
            this.applicationForms.find( form => form.id === id ).active = true;
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }

        
    }  
    
    removeForm(id:string|number){
        const formIndex = this.applicationForms.findIndex( form => form.id === id );        
        this.applicationForms.splice(formIndex, 1);                
    }

    getPrimaryForm(){
        return this.applicationForms.find( form => form.type === 'PRIMARY' )
    }

    updateApplicationFormExtra(form: FormGroup){
        this.applicationFormExtra = form;
    }  
    
    clearAllForms(){
        this.applicationForms = [];
        this.applicationFormExtra = null;
    }

    prepareRequestFormData(){

        const base64regex = /data\:image\/.+?\;base64\,/gm;

        this.applicationForms.forEach(form => {  
            
            let requestData: Object;

            if (form.type === 'PRIMARY') {

                requestData = {
                    ...(form.applicationId) && {applicationId: form.applicationId},
                    ...{
                        type: 'PRIMARY',
                        nameAsId: form.formData.get('name').value,
                        idNumber: form.formData.get('icNumber').value,
                        religion: form.formData.get('religion').value,
                        address: {
                            addressType: "RESIDENTIAL",
                            address1: form.formData.get('address1').value,
                            address2: form.formData.get('address2').value,
                            city: form.formData.get('city').value,
                            postCode: form.formData.get('postCode').value,
                            state: form.formData.get('state').value,
                        },
                        gender: form.formData.get('gender').value,
                        email: form.formData.get('email').value,
                        phoneNumber: form.formData.get('phoneNumber').value,
                        // occupation: form.formData.get('occupation').value,
                        occupation: 'N/A',
                        idFront: this.utilService.resizePhoto(form.formData.get('icPhoto.frontBase64').value, 2*1024*1000).replace(base64regex, ""),
                        idBack: this.utilService.resizePhoto(form.formData.get('icPhoto.backBase64').value, 2*1024*1000).replace(base64regex, "")                        
    
                    }
                }
                
            }
            else if (form.type === 'DEPENDENT') {

                requestData = {
                    ...(form.applicationId) && {applicationId: form.applicationId},
                    ...{
                        type: 'DEPENDENT',
                        nameAsId: form.formData.get('name').value,
                        idNumber: form.formData.get('icNumber').value,
                        religion: form.formData.get('religion').value,
                        address: {
                            addressType: "RESIDENTIAL",
                            address1: form.formData.get('address1').value,
                            address2: form.formData.get('address2').value,
                            city: form.formData.get('city').value,
                            postCode: form.formData.get('postCode').value,
                            state: form.formData.get('state').value,
                        },
                        gender: form.formData.get('gender').value,
                        email: form.formData.get('email').value,
                        phoneNumber: form.formData.get('phoneNumber').value,
                        // occupation: form.formData.get('occupation').value,
                        occupation: 'N/A',
                        relationship: form.formData.get('relationship').value,
                        idFront: this.utilService.resizePhoto(form.formData.get('icPhoto.frontBase64').value, 2*1024*1000).replace(base64regex, ""),
                        idBack: this.utilService.resizePhoto(form.formData.get('icPhoto.backBase64').value, 2*1024*1000).replace(base64regex, "")                        
                    }
                };
                
            }

            this.applicationInfos.push(requestData)

           
        })

        const isUpdatingDependentOnly = this.applicationForms.some( form => {
            return form.type === 'DEPENDENT' && form.applicationId
        })

        if(!isUpdatingDependentOnly){
            const extraFormData = this.applicationFormExtra;

            // this.applicationExtraInfos = {
            //     car: extraFormData.get('car').value,
            //     home: extraFormData.get('home').value,
            //     income: extraFormData.get('income').value,
            //     loan: extraFormData.get('loan').value,
            // }
        
            this.applicationExtraInfos = {
                car: '',
                home: '',
                income: '',
                loan: '',
            }
        }

        return {
            applicationInfos: this.applicationInfos,
            applicationExtraInfos: this.applicationExtraInfos
        }
    }

}

export interface ApplicationFormsInterface {
    id: string,
    type: string;
    active: boolean;
    applicationId?: string;
    formData?: FormGroup;

}