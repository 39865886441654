import { Injectable, Output, EventEmitter } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from "./auth.service";
import { type } from "os";

export type policyType = {
	type: string,
	nameAsId: string,
	idNumber: string,
	certificateNumber: string,
	policyId: string,
	eligible: boolean,
	applicationId?: string
}

export type IdExistResponse = {
	msg: string,
	data: {
		exist: boolean
	}
}

@Injectable({
	providedIn: 'root'
})
export class PolicyService {

	policies: policyType[] = [];
	@Output() onPoliciesUpdated = new EventEmitter<policyType[]>()

	constructor(
		private http: HttpClient,
		private authService: AuthService
	) { }

	addPolicy(data: any) {
		return this.http.post(`${environment.apiUrl}/api/policy/add/INSAN2`, data)
	}

	loadPolicies() {

		this.authService.checkAuth();

		return new Promise((resolve, reject) => {
			this.http.get(`${environment.apiUrl}/api/policy/my`).subscribe({
				next: (result:any) => {					
					resolve(result);
				},
				error: (error) => {
					reject(error);
				}
			})
		})
	}

	addPolicies(policies: policyType[]){
		this.policies.push(...policies)
		this.onPoliciesUpdated.emit(this.policies);
	}

	updatePolicies(policies: policyType[]) {
		this.policies = policies;
		this.onPoliciesUpdated.emit(this.policies);
	}

	getPolicies() {
		return this.policies;
	}

	getCertUrl(policyId: string) {
	
		return new Promise<any>((resolve, reject)=>{
			this.http.get(
				`${environment.apiUrl}/api/policy/e-cert-url/${policyId}`
			).subscribe({
				next: data => {

					resolve(data)
				
				},
				error: error => reject(error)
			})
		})		
	}

	getCert(policyId: string) {

		let headers = new HttpHeaders();
		headers = headers.set('Accept', 'application/pdf');

		return new Promise<Blob | any>((resolve, reject)=>{
			this.http.get(
				`${environment.apiUrl}/api/policy/e-cert-blob/${policyId}`,
				{ headers: headers, responseType: 'blob' }
			).subscribe({
				next: data => {

					resolve(data)
				
				},
				error: error => reject(error)
			})
		})

		
	}

	

	isIdNumberExist(idNumber:string){
		return new Promise<IdExistResponse>( (resolve, reject)=>{
			this.http.post(
				`${environment.apiUrl}/api/policy/is-id-number-exist/INSAN2`, 
				{idNumber}
			).subscribe({
				next: (data:IdExistResponse) => resolve(data),
				error: error => reject(error)
			})
		} )
	}
}
