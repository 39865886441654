import { NgModule } from '@angular/core';

import { LayoutMobileHomeComponent } from './layout-mobile-home/layout-mobile-home.component';
import { LayoutMobileHomeMainComponent } from './layout-mobile-home-main/layout-mobile-home-main.component';
import { LayoutMobileMainComponent } from './layout-mobile-main/layout-mobile-main.component';
import { LayoutBottomNavComponent } from './layout-components/bottom-nav/bottom-nav.component';

import { SharedModule } from '../shared/shared.module';
import { LayoutMobileTncComponent } from './layout-mobile-tnc/layout-mobile-tnc.component';

@NgModule({
    imports: [
        SharedModule
    ],
    providers: [],
    declarations: [
        LayoutMobileTncComponent,
        LayoutMobileHomeComponent,
        LayoutMobileHomeMainComponent,
        LayoutMobileMainComponent,
        LayoutBottomNavComponent        
    ],
    exports: [
                    
    ]
})
export class LayoutMobileModule { }
