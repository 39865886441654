import { Component } from '@angular/core';



@Component({
    selector: 'app-layout-mobile-main',
    templateUrl: './layout-mobile-main.component.html',
    styleUrls: ['./layout-mobile-main.component.scss']
})
export class LayoutMobileMainComponent {

    

    constructor() {
                
    }

   
}


