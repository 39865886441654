

const UserManagement = {
    text: 'User Management',
    link: '/user/user',
    icon: 'icon-home'
};

const MerchantAgent = {
    text: 'Recruitment Agent',
    link: '/agent/agent',
    icon: 'icon-home'
};

const MerchantManagement = {
    text: 'Merchant Management',
    link: '/merchant/merchant',
    icon: 'icon-chemistry'
};

const SettlementTransaction = {
    text: 'Settlement Transaction',
    link: '/settlement/settlement',
    icon: 'icon-speedometer'
};

const TransactionHistory = {
    text: 'Transaction History',
    link: '/history/history',
    icon: 'icon-grid'
};

const SoftpinTransaction = {
    text: 'Softpin Transaction',
    link: '/softpin/softpin',
    icon: 'icon-grid'
};


export const menu = [
    UserManagement,
    MerchantAgent,
    MerchantManagement,
    SettlementTransaction,
    TransactionHistory,
    SoftpinTransaction
];
