<div class="row mt-3 contacts">
    <div class="col-12">
        <div class="contacts__title">
            AmGeneral Insurance Berhad Service Centre
        </div> 
    </div>

    <div class="col-12 contacts__table">


        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>No.</th>
                    <th>Address</th>
                    <th class="text-center">Contact No.</th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let contact of contacts; index as i">
                    <td>
                        {{ i+1 }}
                    </td>

                    <td>
                        <address class="mb-0">{{ contact.address }}</address>
                    </td>

                    <td class="text-center text-nowrap">
                        {{ contact.phone }}
                    </td>
                </tr>
            </tbody>
        </table>


    </div>

    <div class="col-12 contacts__list">
        <div *ngFor="let contact of contacts; index as i">
            <div class="contacts__list__item">
                <address class="mb-0">
                    {{ contact.address }}
                </address>
                <div>
                    <strong>{{ contact.phone }}</strong>
                </div>
            </div>
        </div>
    </div>
</div>