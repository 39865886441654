<div class="container-fluid px-4">
    <div class="row">
        <div class="col-12">
            <app-application-term-details [readOnly]="false"></app-application-term-details>
            <div class="d-flex justify-content-center continue mt-4 text-secondary">
                <a class="btn btn-blood" (click)="back()">Balik</a>
            </div>
        </div>
    </div>
</div>

