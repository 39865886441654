<div class="container-fluid px-4">
    <div class="row">
        <div class="col-12">

            <div class="d-flex justify-content-center mt-6">
                <img class="img-fluid success-img" src="assets/img/insurance/insurance.png" alt="">
            </div>

            <app-simple-spinner *ngIf="!policiesLoaded"></app-simple-spinner>            

            <app-ecert-summary-detail [policies]="policies" *ngIf="policiesLoaded"></app-ecert-summary-detail>
            
            <div class="d-flex justify-content-center continue">
                <button type="button" class="btn btn-rhino" (click)="continue()">
                    OK
                </button>
            </div>
            
        </div>
    </div>
</div>