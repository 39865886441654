import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { EcertSummaryDetailComponent } from './ecert-summary/ecert-summary-detail/ecert-summary-detail.component';

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [        
        EcertSummaryDetailComponent   
    ],
    exports: [
        EcertSummaryDetailComponent
    ],
    providers: [
        
    ]
})
export class EcertSharedModule { }
