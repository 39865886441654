import { Component, OnInit, Input, EventEmitter, Output, ElementRef } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { GraphqlCommonService } from '@services/graphqlCommonService.service'
import { FormGroup, FormControl, Validators, AbstractControl } from "@angular/forms";
import { ApplicationFormInterface } from '@models/applicationform'
import { ApplicationFormsInterface, ApplicationFormsService } from '@services/applicationforms.service'
import { UtilService } from '@services/util.service'
import { PostCodeInfoInterface } from '@models/postcodeinfo';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
// import { occupations, excludedOccupations } from '@models/occupations';
import { PolicyService, policyType, IdExistResponse } from '@services/policy.service';
import { UserService } from '@services/user.service';


interface ageErrorEventInterface {
    code: string;
    exit: boolean;
}

@Component({
    selector: 'app-application-form',
    templateUrl: './application-form.component.html',
    styleUrls: ['./application-form.component.scss']
})
export class ApplicationFormComponent implements OnInit {    

    applicationForm: FormGroup;
    @Input() applicantType: string;
    @Input() applicationId: string;
    @Input() id: string;    
    @Output() onAddNewApplicant = new EventEmitter()
    @Output() onAgeError = new EventEmitter<ageErrorEventInterface>()
    @Output() onShowExemptedJobList = new EventEmitter()    
    // occupations = occupations.sort(this.sortOccupationsByName);    
    // excludedOccupations = excludedOccupations.sort(this.sortOccupationsByName); 
    
    mask = this.utilService.icMask;

    formModel: ApplicationFormInterface = {
        name: '',
        icNumber: '',
        religion: 'muslim',
        address1: '',
        address2: '',
        city: '',
        postCode: '',
        state: '',
        gender: '',
        email: '',
        phoneNumber: '',
        // occupation: undefined,
        relationship: '',
        icPhotoFront: '',        
        icPhotoBack: '',
        icPhotoFrontbase64: '',
        icPhotoBackbase64: '',
        stateConfirm: false,
        agreeToc: false,
        // occupationConfirm: false      
    }
    
    searchPostCode = this.utilService.debounce(this.doSearchPostCode.bind(this), 600)

    isSubmitting = false;

    userInfo: any = {
        nameAsId: '',
        idNumber: ''
    }  

    constructor(
        private commonGraph: Apollo,
        public graphqlCommonService: GraphqlCommonService,
        public applicationFormsService: ApplicationFormsService,
        private utilService: UtilService,        
        private router: Router,
        private el:ElementRef,
        private policyService: PolicyService,
        private userService:UserService
    ) { }

    async ngOnInit() {            

        if (this.applicantType === 'PRIMARY') {
            
            this.userInfo = await this.userService.getUser()            

            if(this.applicationId){
                const policies:policyType[] = this.policyService.getPolicies()
                const policy = policies.find( p => p.applicationId === this.applicationId )
                
                this.userInfo.nameAsId = policy.nameAsId;
                this.userInfo.idNumber = policy.idNumber;
            }

            await this.prepopulateUserProfileInfo();
            this.initForm()
        }
        else{

            if(this.applicationId){
                const policies:policyType[] = this.policyService.getPolicies()
                const policy = policies.find( p => p.applicationId === this.applicationId )                
                
                this.userInfo.nameAsId = policy.nameAsId;
                this.userInfo.idNumber = policy.idNumber;

                await this.prepopulateUserProfileInfo();
                this.initForm()
            }
            else{
                this.initForm()
            }

            
        }        
    } 
    
    // sortOccupationsByName(a,b){
    //     if (a.name < b.name) {
    //         return -1;
    //     }
    //     if (a.name > b.name) {
    //         return 1;
    //     }
    //     return 0;
    // }

    prepopulateUserProfileInfo() {      

        return new Promise( (resolve, reject)=>{
            this.formModel.name = this.userInfo?.nameAsId;
            this.formModel.icNumber = this.userInfo?.idNumber;                        
            this.formModel.address1 = this.userInfo?.addresses?.[0]?.address1;
            this.formModel.address2 = this.userInfo?.addresses?.[0]?.address2;
            this.formModel.city = this.userInfo?.addresses?.[0]?.city;
            this.formModel.state = this.userInfo?.addresses?.[0]?.state;
            this.formModel.postCode = this.userInfo?.addresses?.[0]?.postCode;
            this.formModel.gender = this.getGenderFromIcNumber(this.formModel.icNumber);
            this.formModel.email = this.userInfo?.email;
            this.formModel.phoneNumber = this.userInfo?.phone;
            // this.formModel.occupation = this._resolveOccupation(this.userInfo?.occupation);

            if(this.formModel.postCode){              
                this.graphqlCommonService.searchPostCode(this.formModel.postCode).subscribe({
                    next:(data: PostCodeInfoInterface) => {
                        this.formModel.city = data.cities[0].city
                        this.formModel.state = data["state_name"]
                    },
                    error:(error) => {
                        this.formModel.city = ""
                        this.formModel.state = ""
                    },
                    complete: ()=>{
                        resolve(true)
                    }
                })
            }
            else{
                resolve(true)
            }
        } )        

    }

    // private _resolveOccupation(occ:string){
        
    //     if( this.occupations.some( occupation => {            
    //         return occupation.code === occ;
    //     }) )
    //     {
    //         return occ;
    //     }

    //     return undefined;
    // }

    updateGenderOnIcChange() {
        const icNumber = this.applicationForm.get('icNumber').value;
        const gender = this.getGenderFromIcNumber(icNumber);
        this.applicationForm.get('gender').setValue(gender);
    }

    getGenderFromIcNumber(icNumber: string) {
        if(icNumber){
            const lastNumber = +icNumber.slice(-1);

            if (lastNumber % 2 === 0) {
                return 'female';
            }

            return 'male';
        }

        return '';
    }    

    //  postcodeinvalid
    postCodeInvalid(control: FormControl): Promise<any> | Observable<any> {

        return this.graphqlCommonService.searchPostCode(control.value.trim())
            .toPromise()
            .then(data => {
                return null
            })
            .catch(error => {
                return { 'postCodeInvalid': true }
            })

    }

    initForm() {

        if (this.applicantType === 'PRIMARY') {
            this.applicationForm = new FormGroup({
                name: new FormControl({
                    value: this.formModel.name,
                    disabled: true
                }, [Validators.required]),
                icNumber: new FormControl(
                    {
                        value: this.formModel.icNumber,
                        disabled: true
                    },
                    {
                        validators: [Validators.required, this.validateIcNumber],
                        // ...(!this.applicationId) && {asyncValidators: [this.validateIcNumberUnique.bind(this)]},
                        updateOn: 'blur'
                    } 
                    
                ),
                religion: new FormControl({
                    value: this.formModel.religion,
                    disabled: false
                }),
                address1: new FormControl({
                    value: this.formModel.address1,
                    disabled: false
                }, [Validators.required]),
                address2: new FormControl({
                    value: this.formModel.address2,
                    disabled: false
                }),
                city: new FormControl({
                    value: this.formModel.city,
                    disabled: true
                }),
                postCode: new FormControl(
                    {
                        value: this.formModel.postCode,
                        disabled: false
                    },
                    {
                        validators: [Validators.required],
                        asyncValidators: [this.postCodeInvalid.bind(this)],
                        updateOn: 'blur'
                    }
                ),
                state: new FormControl({
                    value: this.formModel.state,
                    disabled: true
                }),
                gender: new FormControl({
                    value: this.formModel.gender,
                    disabled: false
                }, [Validators.required]),
                email: new FormControl({
                    value: this.formModel.email,
                    disabled: false
                }, [Validators.email, Validators.required]),
                phoneNumber: new FormControl({
                    value: this.formModel.phoneNumber,
                    disabled: false
                }, [Validators.required]),
                // occupation: new FormControl(
                // {
                //     value: this.formModel.occupation,
                //     disabled: false
                // }, 
                // {
                //     validators: [Validators.required]                    
                // }),
                stateConfirm: new FormControl({
                    value: this.formModel.stateConfirm,
                    disabled: false
                }, [Validators.requiredTrue]),
                agreeToc: new FormControl({
                    value: this.formModel.agreeToc,
                    disabled: false
                }, [Validators.requiredTrue]),
                // occupationConfirm: new FormControl({
                //     value: this.formModel.occupationConfirm,
                //     disabled: false
                // }, [Validators.requiredTrue]),
                icPhoto: new FormGroup({
                    front: new FormControl({
                        value: this.formModel.icPhotoFront,
                        disabled: false
                    }, [Validators.required]),
                    back: new FormControl({
                        value: this.formModel.icPhotoBack,
                        disabled: false
                    }, [Validators.required]),
                    frontBase64: new FormControl({
                        value: '',
                        disabled: false
                    }),
                    backBase64: new FormControl({
                        value: this.formModel.icPhotoBack,
                        disabled: false
                    })
                    
                })                
            });
        }
        else if (this.applicantType === 'DEPENDENT') {
            
            this.applicationForm = new FormGroup({
                name: new FormControl({
                    value: this.formModel.name,
                    disabled: false
                }, [Validators.required]),                
                icNumber: new FormControl({
                    value: this.formModel.icNumber,
                    disabled: !!this.applicationId
                },{
                    validators: [
                        Validators.required, 
                        this.validateIcNumber,
                        // this.validateIcNumberDuplicate.bind(this)                        
                    ],
                    // ...(!this.applicationId) && {asyncValidators: [this.validateIcNumberUnique.bind(this)]},
                    updateOn: 'blur'    
                } ),
                religion: new FormControl({
                    value: this.formModel.religion,
                    disabled: false
                }),
                address1: new FormControl({
                    value: this.formModel.address1,
                    disabled: false
                }, [Validators.required]),
                address2: new FormControl({
                    value: this.formModel.address2,
                    disabled: false
                }),
                city: new FormControl({
                    value: this.formModel.city,
                    disabled: true
                }),
                postCode: new FormControl(
                    {
                        value: this.formModel.postCode,
                        disabled: false
                    },
                    {
                        validators: [Validators.required],
                        asyncValidators: [this.postCodeInvalid.bind(this)],
                        updateOn: 'blur'
                    }
                ),
                state: new FormControl({
                    value: this.formModel.state,
                    disabled: true
                }),
                gender: new FormControl({
                    value: this.formModel.gender,
                    disabled: false
                }),
                email: new FormControl({
                    value: this.formModel.email,
                    disabled: false
                }),
                phoneNumber: new FormControl({
                    value: this.formModel.phoneNumber,
                    disabled: false
                }),
                // occupation: new FormControl(
                // {
                //     value: this.formModel.occupation,
                //     disabled: false
                // }, 
                // {
                //     validators: [Validators.required],
                //     updateOn: 'change'
                // }),
                relationship: new FormControl({
                    value: this.formModel.relationship,
                    disabled: false
                }, [Validators.required]),
                icPhoto: new FormGroup({
                    front: new FormControl({
                        value: this.formModel.icPhotoFront,
                        disabled: false
                    }, [Validators.required]),
                    back: new FormControl({
                        value: this.formModel.icPhotoBack,
                        disabled: false
                    }, [Validators.required]),
                    frontBase64: new FormControl({
                        value: '',
                        disabled: false
                    }),
                    backBase64: new FormControl({
                        value: this.formModel.icPhotoBack,
                        disabled: false
                    })
                    
                })

            });
        }

        this.checkIfAgeError()
        
    }

    checkIfAgeError() {        

        // primary: 18 years - 80 years
        // dependent: 30 days - 80 years

        
        const icNumber: string = this.applicationForm.get('icNumber').value; 
            

        if (icNumber && ( 
                this.applicationForm.get('icNumber').status === 'DISABLED' || 
                this.applicationForm.get('icNumber').valid )
        ) {            

            const ageResult: {age:number, unit:string} = this.utilService.getAgeFromIc(icNumber)                     
                       
            if ( this.applicantType === 'PRIMARY' && 
                ( 
                    (ageResult.unit === 'Year' && +ageResult.age < 18) || 
                    ageResult.unit === 'Day'
                ) 
            ) 
            {                
                setTimeout(() => {
                    this.onAgeError.emit({
                        code:"PRIMARY_BELOW_18",
                        exit: true
                    })
                }, 300)
            }
            else if( this.applicantType === 'DEPENDENT' && ageResult.unit === 'Day' &&  +ageResult.age < 30  ){
                
                setTimeout(() => {
                    this.onAgeError.emit({
                        code: "DEPENDENT_BELOW_30",
                        exit: false
                    })
                }, 300)

                this.applicationForm.get('icNumber').setValue('');
            }
            
            else if(+ageResult.age > 80 && ageResult.unit === 'Year'){
                if(this.applicantType === 'PRIMARY'){
                    setTimeout(() => {                    
                        this.onAgeError.emit({
                            code:"PRIMARY_ABOVE_80",
                            exit: true
                        });
                    }, 300)
                } 
                else{
                    this.applicationForm.get('icNumber').setValue('');
                    setTimeout(() => {                    
                        this.onAgeError.emit({
                            code:"DEPENDENT_ABOVE_80",
                            exit: false
                        });
                    }, 300)
                }               
                
                
            }
        }
    }

    // handleExemptedJobListClicked() {        
    //     this.onShowExemptedJobList.emit()
    // }

    // checkExemptedOccupation(e){
    //     const matchOcc = excludedOccupations.find( occ => occ.code === e.target.value );

    //     if(matchOcc){
    //         this.onShowExemptedJobList.emit()
    //     }
    // }

    addNewApplicant() {
        this.applicationFormsService.updateForm(this.id, this.applicationForm)
        this.applicationFormsService.addApplication('DEPENDENT')
    }

    activateForm(event, id) {
        event.preventDefault()
        this.applicationFormsService.updateForm(this.id, this.applicationForm)
        this.applicationFormsService.activateForm(id)
    }


    setNomineeRelationship(event) {
        const selectedRelationship = event.target.value;

        const primaryForm = this.applicationFormsService.getPrimaryForm();
        const primaryApplicantGender = primaryForm.formData.get('gender').value;

        const relationshipMap = {
            "Father": primaryApplicantGender === 'male' ? "Son" : "Daughter",
            "Mother": primaryApplicantGender === 'male' ? "Son" : "Daughter",
            "Son": primaryApplicantGender === 'male' ? "Father" : "Mother",
            "Daughter": primaryApplicantGender === 'male' ? "Father" : "Mother",
            "Brother": primaryApplicantGender === 'male' ? "Younger brother" : "Younger sister",
            "Sister": primaryApplicantGender === 'male' ? "Younger brother" : "Younger sister",
            "Younger brother": primaryApplicantGender === 'male' ? "Brother" : "Sister",
            "Younger sister": primaryApplicantGender === 'male' ? "Brother" : "Sister",
            "Guardian": "Ward",
            "Ward": "Guardian",
            "Uncle": primaryApplicantGender === 'male' ? "Nephew" : "Niece",
            "Auntie": primaryApplicantGender === 'male' ? "Nephew" : "Niece",
            "Nephew": primaryApplicantGender === 'male' ? "Uncle" : "Auntie",
            "Niece": primaryApplicantGender === 'male' ? "Uncle" : "Auntie"
        }

        this.applicationForm.get('nominee.relationship').setValue(relationshipMap[selectedRelationship])


    }

    

    doSearchPostCode(event) {     

        this.graphqlCommonService.searchPostCode(event.target.value.trim()).subscribe(
            (data: PostCodeInfoInterface) => {
                this.applicationForm.get('city').setValue(data.cities[0].city)
                this.applicationForm.get('state').setValue(data["state_name"])
            },

            (error) => {

                this.applicationForm.get('city').setValue('')
                this.applicationForm.get('state').setValue('')

                error.scope = 'searchPostCode'
                throw error;
            }
        )


    }   

    validateIcNumber(control:AbstractControl){
        
        if(control.value && control.value !== '' && !control?.value.match(/^\d{6}\-\d{2}\-\d{4}$/gm)){            
            return { invalidIcNumber: true }
        }
        return null;        
    }

    // validateIcNumberDuplicate(control:AbstractControl){              
        
    //     const matchedForms: boolean = this.applicationFormsService.applicationForms.some( form => {
    //         return form.formData?.get('icNumber').value === control.value &&
    //         form.id !== this.id
    //     } )

    //     if(matchedForms ){
    //         return { icNumberDuplicate: true }
    //     }

    //     return null;
    // }

    // validateIcNumberUnique(control: FormControl): Promise<any> | Observable<any> {

    //     return this.policyService.isIdNumberExist(control.value.trim())
    //     .then((data:IdExistResponse) =>{

    //         if(data.data.exist){
    //             return { icNumberExist: true }
    //         }

    //         return null;
    //     })      

    // }

    private scrollToFirstInvalidControl() {
        const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
          "form .ng-invalid"
        );
    
        firstInvalidControl.focus(); //without smooth behavior
      }
    
    continue() {
        
        this.applicationForm.markAllAsTouched();        

        if(!this.applicationForm.valid){

            this.scrollToFirstInvalidControl()

            return;           
        }

        this.applicationFormsService.updateForm(this.id, this.applicationForm)

        // first create
        if(!this.applicationId){
            if(this.applicantType == 'DEPENDENT'){
                this.applicationFormsService.activateForm('PRIMARY');
            }
            else{                
                this.router.navigate(['/application', 'term']);
            }            
        }

        // edit
        else{
            this.submit()            
        }

         
    }

    submit(){

        this.isSubmitting = true;
        
        const data = this.applicationFormsService.prepareRequestFormData();

        this.addPolicy(data);
    }

    addPolicy = this.utilService.debounce(this._doAddPolicy.bind(this), 3000)
    _doAddPolicy(data:any){
        this.policyService.addPolicy(data).subscribe({
            next: (result: any) => {                
                this.isSubmitting = false;
                this.router.navigate(['/ecert/ecert'])
            },
            error: error => {
                this.isSubmitting = false;
                throw error;
            }
        })
    }    

    cancel() {
        this.applicationFormsService.removeForm(this.id)
        // if not in editing, no application id, back to primary
        if(!this.applicationId){            
            this.applicationFormsService.activateForm('PRIMARY')
        }
        // got application id, coming from cert page, back to it
        else{            
            this.router.navigateByUrl('/ecert/ecert');
        }
        
    }   












}