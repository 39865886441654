import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';

@Injectable()
export class MenuService {

    menuItems: Array<any>;
    jwtHelper = new JwtHelperService();
    token: string = localStorage.getItem('token');
    decodedToken = this.jwtHelper.decodeToken(this.token);

    constructor(private router: Router) {
        this.menuItems = [];
    }

    addMenu(items: Array<{
        text: string,
        heading?: boolean,
        link?: string,     // internal route links
        elink?: string,    // used only for external links
        target?: string,   // anchor target="_blank|_self|_parent|_top|framename"
        icon?: string,
        alert?: string,
        submenu?: Array<any>
    }>) {
        items.forEach((item) => {
            this.menuItems.push(item);
        });
    }

    getMenu() {
        this.getrole();
        return this.menuItems;
    }

    getrole() {
        const token: string = localStorage.getItem('token');
        const decodedToken = this.jwtHelper.decodeToken(token);
        if (!token || this.jwtHelper.isTokenExpired(token)) {
            this.router.navigate(['/login']);
        }
        if (decodedToken.role === 'ROLE_MERCHANT_ACQUIRER' || decodedToken.role === 'ROLE_MERCHANT_SUPPORT') {
            this.menuItems.forEach((item, index) => {
                if (item.text === 'User Management') {
                    this.menuItems.splice(index, 1);
                }
            });
        }
        if (decodedToken.role === 'ROLE_MERCHANT_CDD_OFFICER') {
            this.menuItems.forEach((item, index) => {
                if (item.text !== 'Merchant Management') {
                    this.menuItems.splice(index, 1);
                }
            });
        }
    }

}
