<div class="container-fluid px-4">
    <h1 class="text-eerie text-center title">
        <p>Maklumat Tambahan</p>
    </h1>

    <div class="row">
        <div class="col-12">

            <form action="" [formGroup]="applicationFormExtra">

            <div class="row">
                
                <div class="col-12">
                    <!-- Income -->
                    <div class="form-group">
                        <label for="income" class="font-weight-bold">Jumlah Pendapatan</label>

                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="income" id="income1" value="-2000" formControlName="income">
                            <label class="form-check-label" for="income1">
                                Bawah RM2,000
                            </label>
                        </div>

                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="income" id="income2" value="2000-3999" formControlName="income">
                            <label class="form-check-label" for="income2">
                                RM2,000 hingga RM3,999
                            </label>
                        </div>

                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="income" id="income3" value="4000+" formControlName="income">
                            <label class="form-check-label" for="income3">
                                RM4000 dan keatas
                            </label>
                        </div>

                    </div>
                </div>

                <div class="col-12">
                    <!-- Car -->
                    <div class="form-group">

                        <label for="car" class="font-weight-bold">Adakah anda memiliki kereta?</label>

                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="car" id="car1" value="yes" formControlName="car">
                            <label class="form-check-label" for="car1">
                                Ya
                            </label>
                        </div>

                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="car" id="car2" value="no" formControlName="car">
                            <label class="form-check-label" for="car2">
                                Tidak
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <!-- Home -->
                    <div class="form-group">

                        <label for="home" class="font-weight-bold">Adakah anda memiliki rumah?</label>

                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="home" id="home1" value="yes" formControlName="home">
                            <label class="form-check-label" for="home1">
                                Ya
                            </label>
                        </div>

                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="home" id="home2" value="no" formControlName="home">
                            <label class="form-check-label" for="home2">
                                Tidak
                            </label>
                        </div>
                        
                    </div>
                </div>

                <div class="col-12">
                    <!-- Loan -->
                    <div class="form-group">

                        <label for="loan" class="font-weight-bold">Adakah anda mempunyai pinjaman peribadi?</label>

                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="loan" id="loan1" value="yes" formControlName="loan">
                            <label class="form-check-label" for="loan1">
                                Ya
                            </label>
                        </div>

                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="loan" id="loan2" value="no" formControlName="loan">
                            <label class="form-check-label" for="loan2">
                                Tidak
                            </label>
                        </div>
                        
                    </div>
                </div>

                <div class="col-12 d-flex justify-content-center continue">
                    <button [disabled]="!applicationFormExtra.valid" type="button" class="btn btn-rhino" (click)="continue()">
                        Teruskan
                    </button>
                </div>

            </div>

            </form>
        </div>
    </div>
</div>