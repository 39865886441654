import { Component, Input, OnInit, Output, EventEmitter, ElementRef } from "@angular/core";
import { FormGroup, FormControl, Validators, AbstractControl, ValidatorFn } from "@angular/forms";
import { GraphqlCommonService } from "@services/graphqlCommonService.service";
import { UtilService } from "@services/util.service";
import { PostCodeInfoInterface } from "@models/postcodeinfo";
import { Observable } from "rxjs";
import { occupations, excludedOccupations } from '@models/occupations';
import { ApplicationMoreFormsService } from "@services/applicationMoreForms.service";
import { PolicyService, IdExistResponse } from "@services/policy.service";

@Component({
    selector: 'app-application-more-form',
    templateUrl: './application-more-form.component.html',
    styleUrls: ['./application-more-form.component.scss']
})
export class ApplicationMoreFormComponent implements OnInit {

    @Input() id: string;

    @Output() onShowExemptedJobList = new EventEmitter()

    mask = this.utilService.icMask;

    // occupations = occupations.sort(this.sortOccupationsByName);        
    applicationForm: FormGroup;
    searchPostCode = this.utilService.debounce(this.doSearchPostCode.bind(this), 600)


    constructor(
        public graphqlCommonService: GraphqlCommonService,
        public utilService: UtilService,
        public formService: ApplicationMoreFormsService,  
        private el: ElementRef,
        private policyService: PolicyService
    ){}

    

    ngOnInit(){
        this.initForm()
    }

    // sortOccupationsByName(a,b){
    //     if (a.name < b.name) {
    //         return -1;
    //     }
    //     if (a.name > b.name) {
    //         return 1;
    //     }
    //     return 0;
    // }

    initForm(){
        this.applicationForm = new FormGroup({
            name: new FormControl({
                value: '',
                disabled: false
            }, [Validators.required]),        
            icNumber: new FormControl({
                value: '',
                disabled: false
            },{
                validators: [
                    Validators.required, 
                    this.validateIcNumber, 
                    // this.validateIcNumberDuplicate.bind(this)
                ],
                // asyncValidators: [this.validateIcNumberUnique.bind(this)],
                updateOn: 'blur'    
            } ),
            religion: new FormControl({
                value: 'muslim',
                disabled: false
            }),
            agreeToc: new FormControl({
                value: false,
                disabled: false
            }, [this.requiredTrueValidator()]),
            address1: new FormControl({
                value: '',
                disabled: false
            }, [Validators.required]),
            address2: new FormControl({
                value: '',
                disabled: false
            }),
            city: new FormControl({
                value: '',
                disabled: true
            }),
            postCode: new FormControl(
                {
                    value: '',
                    disabled: false
                },
                {
                    validators: [Validators.required],
                    asyncValidators: [this.postCodeInvalid.bind(this)],
                    updateOn: 'blur'
                }
            ),
            state: new FormControl({
                value: '',
                disabled: true
            }),
            gender: new FormControl({
                value: '',
                disabled: false
            }),
            email: new FormControl({
                value: '',
                disabled: false
            }, [Validators.email]),
            phoneNumber: new FormControl({
                value: '',
                disabled: false
            }),
            // occupation: new FormControl(
            // {
            //     value: undefined,
            //     disabled: false
            // }, 
            // {
            //     validators: [Validators.required],
            //     updateOn: 'change'
            // }),
            relationship: new FormControl({
                value: '',
                disabled: false
            }, [Validators.required]),
            icPhoto: new FormGroup({
                front: new FormControl({
                    value: '',
                    disabled: false
                }, [Validators.required]),
                back: new FormControl({
                    value: '',
                    disabled: false
                }, [Validators.required]),
                frontBase64: new FormControl({
                    value: '',
                    disabled: false
                }),
                backBase64: new FormControl({
                    value: '',
                    disabled: false
                })
                
            })

        });


    }

    requiredTrueValidator(): ValidatorFn {
        return (control: FormControl) => {
            if (control.value !== true) {
                return { required: true };
            }
            return null;
        };
    }

    updateGenderOnIcChange() {
        const icNumber = this.applicationForm.get('icNumber').value;
        const gender = this.getGenderFromIcNumber(icNumber);
        this.applicationForm.get('gender').setValue(gender);
    }

    getGenderFromIcNumber(icNumber: string) {
        if(icNumber){
            const lastNumber = +icNumber.slice(-1);

            if (lastNumber % 2 === 0) {
                return 'female';
            }

            return 'male';
        }

        return '';
    }

    doSearchPostCode(event) {     

        this.graphqlCommonService.searchPostCode(event.target.value.trim()).subscribe(
            (data: PostCodeInfoInterface) => {
                this.applicationForm.get('city').setValue(data.cities[0].city)
                this.applicationForm.get('state').setValue(data["state_name"])
            },

            (error) => {

                this.applicationForm.get('city').setValue('')
                this.applicationForm.get('state').setValue('')
            }
        )


    }

    // checkExemptedOccupation(e){
    //     const matchOcc = excludedOccupations.find( occ => occ.code === e.target.value );

    //     if(matchOcc){
    //         this.onShowExemptedJobList.emit()
    //     }
    // }

    validateIcNumber(control:AbstractControl){
        
        if(control.value && control.value !== '' && !control?.value.match(/\d{6}\-\d{2}\-\d{4}/gm)){            
            return { invalidIcNumber: true }
        }
        return null;        
    }

    // validateIcNumberDuplicate(control:AbstractControl){              
        
    //     const matchedForms: boolean = this.formService.applicationForms.some( form => {
    //         return form.formData?.get('icNumber').value === control.value &&
    //         form.id !== this.id
    //     } )

    //     if(matchedForms ){
    //         return { icNumberDuplicate: true }
    //     }

    //     return null;
    // }

    // validateIcNumberUnique(control: FormControl): Promise<any> | Observable<any> {        

    //     return this.policyService.isIdNumberExist(control.value.trim())
    //     .then((data:IdExistResponse) =>{

    //         if(data.data.exist){
    //             return { icNumberExist: true }
    //         }

    //         return null;
    //     })      

    // }

    postCodeInvalid(control: FormControl): Promise<any> | Observable<any> {

        return this.graphqlCommonService.searchPostCode(control.value.trim())
            .toPromise()
            .then(data => {
                return null
            })
            .catch(error => {
                return { 'postCodeInvalid': true }
            })

    }

    private scrollToFirstInvalidControl() {
        const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
          "form .ng-invalid"
        );
    
        firstInvalidControl.focus(); //without smooth behavior
      }

    continue() {

        this.applicationForm.markAllAsTouched();
        
        if(!this.applicationForm.valid){

            this.scrollToFirstInvalidControl()

            return;
        }

        this.formService.updateForm(this.id, this.applicationForm)

        this.formService.deactivateAllForm()        
    }

    cancel() {
        this.formService.removeForm(this.id)
    }


}