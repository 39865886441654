import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class UtilService {
    debounce(callback, wait) {

        let timeoutId = null;
        return (...args) => {
            window.clearTimeout(timeoutId);
            timeoutId = window.setTimeout(() => {
                callback.apply(null, args);
            }, wait);
        };
    }

    getAgeFromIc(icNumber) {

        let year = icNumber.substring(0, 2);
        const month = icNumber.substring(2, 4);
        const day = icNumber.substring(4, 6);

        const now = new Date().getFullYear().toString();

        var decade = now.substring(0, 2);           

        if (now.substring(2, 4) > year) {
            year = parseInt(decade.concat(year.toString()), 10);            
        }

        let birthdate = new Date(year, month - 1, day, 0, 0, 0, 0)            

        let age = new Date().getFullYear() - birthdate.getFullYear();

        if(age >= 100){
            year = 2000 + +year;
            birthdate = new Date(year, month - 1, day, 0, 0, 0, 0);
            const Difference_In_Time = new Date().getTime() - birthdate.getTime();
            const Difference_In_Days = Math.trunc(Difference_In_Time / (1000 * 3600 * 24));
            
            return {
                age: Difference_In_Days,
                unit:'Day'
            }

        }

        return {
            age,
            unit:'Year'
        }

    }

    resizePhoto(photo: string, size:number, MAX_WIDTH:number=1024 , MAX_HEIGHT=768) {
        const base64Length = photo.length - (photo.indexOf(',') + 1);
        const padding = (photo.charAt(photo.length - 2) === '=') ? 2 : ((photo.charAt(photo.length - 1) === '=') ? 1 : 0);
        const fileSize = base64Length * 0.75 - padding;

        // if filesize greater than 2MB, resize it down to a max of 1024x768
        if (fileSize > size ) {
            const img = new Image();
            img.src = photo;
            const canvas = document.createElement('canvas');
            
            let width = img.width;
            let height = img.height;

            if (width > height) {
                if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                }
            } else {
                if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                }
            }
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, width, height);
            return canvas.toDataURL();
        }

        return photo
    }

    icMask(rawValue){
        const yearMask = () => {            
            // return [/\[0|1|2|6|7|8|9]/, /\d/]
            return [/\d/, /\d/]
        }

        const monthMask = () => {
            const monthInitial = +rawValue[2];        

            let mask= [/[01]/, /\d/]

            if(monthInitial === 0){                
                mask = [/[0]/, /[1-9]/]
            }
            else if(monthInitial === 1){                
                mask = [/[1]/, /[012]/]
            }

            return mask;
        }
        
        const dayMask = ()=>{
            const dayInitial = +rawValue[4];            

            let mask = [/[012]/, /\d/];

            if(dayInitial === 0 || dayInitial === 1 || dayInitial === 2){
                mask = [/[012]/, /\d/]
            }

            else if(dayInitial === 3){
                mask =  [/[3]/, /[01]/]
            }

            return mask;
        }

        return [...yearMask(), ...monthMask(), ...dayMask(), '-', /\d/, /\d/, '-', /\d/,/\d/,/\d/,/\d/ ]
    }
}
