<ng-template #idVerificationTemplate>

    <div class="idVerification modal-body bg-cultured d-flex flex-column justify-content-center">

        <div>

            <div class="mb-5 text-center px-5">
                Sila masukkan 4 digit terakhir nombor kad pengenalan anda untuk pengesahan
            </div>

            <div>
                <form [formGroup]="idForm" (ngSubmit)="onSubmit()">

                    <div class="d-flex justify-content-center" formArrayName="ids">
                        <div
                        *ngFor="let idControl of controls; let i = index"
                            [formGroupName]="i"
                        >
                            <input 
                                type="number" 
                                [id]="'input-'+i" 
                                formControlName="idNumber" 
                                maxlength="1" 
                                (input)="move(i)" 
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                            />
                        </div>                                            
                    </div>
                    
                    <div class="text-center mt-2">
                        
                        <span 
                            *ngIf="allTouched && !idForm.valid && errorRequired" 
                            class="help-block mt-1 error-message text-urobilin"
                        >
                            All field required.
                        </span>

                        <span 
                            *ngIf="allTouched && idForm.valid && errorNotMatched" 
                            class="help-block mt-1 error-message text-urobilin"
                        >
                            Nombor yang dimasukkan tidak sama dengan rekod.
                        </span>
                    </div>

                    <div class="d-flex justify-content-center mt-5">
                        <button class="btn btn-rhino">
                            SAHKAN
                        </button>
                    </div>
                </form>
            </div>

        </div>

    </div>

</ng-template>