<ng-container *ngIf="!policies.length">

    
    <div class="my-6 text-center">
        Tiada Rekod
    </div>

    <div class="d-flex flex-column align-items-center">
        <button type="button" class="btn btn-rhino control mb-2">
            <a href="#" [routerLink]="['/home']" class="text-secondary">DAFTAR</a>
        </button>

        <button type="button" class="btn btn-rhino control">
            <a href="#" [routerLink]="['..']" class="text-secondary">
                KEMBALI
            </a>
        </button>
    </div>
    
   
</ng-container>

<ng-container *ngIf="policies.length">
    <div>
        <p class="text-center mb-0">Sila semak status kelayakan di bawah</p>
        <p class="font-weight-bold text-center text-black">INSAN</p>
    </div>

    <div class="row my-4">
        <div class="col-10 offset-1">
            <table class="table table-borderless">
                <thead>
                    <tr>
                        <th class="text-center p-1 text-black">Name</th> 
                        <th class="text-center p-1 text-black">Type</th> 
                        <th class="text-center p-1 text-black">No. e-Sijil</th>

                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let policy of policies">
                        <td class="text-center p-1">{{ policy.nameAsId }}</td>
                        <td class="text-center p-1" [ngStyle]="{'text-transform':'capitalize'}">{{ policy.type }}</td>                        
                        <td class="text-center p-1">
                            <a class="text-primary" *ngIf="policy.eligible" [routerLink]="['/ecert/ecert', policy.policyId]" [ngStyle]="{cursor:'pointer'}">{{policy.certificateNumber}}</a>
                            <span *ngIf="!policy.eligible">
                                <a [routerLink]="['/application']" [queryParams]="{'applicationId': policy.applicationId, 'applicationType':policy.type}">Not Entitled</a>
                                <i class="far fa-question-circle ml-2" [ngStyle]="{cursor:'pointer'}" [tooltip]="policy?.notEligibleReason"
                                triggers="click" placement="left"></i>
                                
                            </span>          
                                                
                        </td>
                    </tr>                           
                </tbody>
            </table>

            <div class="my-3 d-flex justify-content-center">                
                <a class="text-rhino font-weight-bold" [routerLink]="['/application', 'more']">
                    Tambah Pemohon +
                </a>
            </div>
        </div>
    </div>

    <div class="sponsor">
        <p class="text-center">Inisiatif oleh</p>

        <div class="d-flex justify-content-center">
            <img class="img-fluid coa" src="assets/img/insurance/coat_of_arms_of_selangor.png" alt="">
        </div>

        <div class="row mb-3">
            <div class="col-4 col-md-2 offset-md-3">
                <p class="text-center">Agensi<br>Pengendali</p>
                <div class="d-flex align-items-center justify-content-center h-50">
                    <img class="img-fluid" src="assets/img/insurance/mbi-logo.png" alt="">
                </div>
                
            </div>
            <div class="col-4 col-md-2">
                <p class="text-center">Pengendali<br>Perkhidmatan</p>
                <div class="d-flex align-items-center justify-content-center h-50">
                <img class="img-fluid" src="assets/img/insurance/wavpay_logo.png" alt="">
                </div>
            </div>
            <div class="col-4 col-md-2">
                <p class="text-center">Ditanggung<br>taja oleh</p>
                <div class="d-flex align-items-center justify-content-center h-50">
                <img class="img-fluid" src="assets/img/insurance/amgen_logo.png" alt="">
                </div>
            </div>
        </div>
       
    </div>
    
</ng-container>


