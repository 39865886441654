import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router';
import { PolicyService, policyType } from '@services/policy.service';

@Component({
    selector: 'app-application-confirm',
    templateUrl: './app-application-confirm.component.html',
    styleUrls: ['./app-application-confirm.component.scss']
})
export class ApplicationConfirmComponent implements OnInit {

    policies: policyType[] = [];
    policiesLoaded = false;

    constructor(
        private router:Router,
        private policyService: PolicyService
    ){}

    async ngOnInit(){       
        
        

        try {

            this.policiesLoaded = false;

            const policy:any = await this.policyService.loadPolicies();
            this.policyService.updatePolicies(policy.data.applicationInfos);
            this.policies = this.policyService.getPolicies();
            
            this.policiesLoaded = true;
            
        } catch (error) {
            const errorMessage = error.error?.error?.[0];

            if(errorMessage && errorMessage === 'No primary application'){
                console.log(errorMessage);
            }

            this.policiesLoaded = true;
            
            throw error;   
        }
    }

    continue(){
        this.router.navigate(['/main'])
    }


}