<div class="container-fluid px-4">

    <div class="row">
        <div class="col-12">

            <a routerLink="..">
                <img class="back-button my-4" src="assets/img/insurance/back_icon.png" alt="">
            </a>

            <app-application-term-details [readOnly]="true"></app-application-term-details>
        </div>
    </div>
</div>

